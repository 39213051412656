.table-bordered th, .table-bordered td { 
    font-size: .95rem;
    line-height: 1em;
    letter-spacing: .01em;
}
.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: rgb(255 255 255 / 5%);
    color: var(--bs-table-striped-color);
}
.trendBar{
    background-color: #cbcbcb;
    border-radius: 10px; 
}
.trendBar .progress-bar {
    background: #865feb;
}
