  /******************************/
  .chat-container {
    display: flex;
    height: 92vh;
    background: #f8f9fa;
  }
  
  .chat-main {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 0;
    position: relative;
    background: white;
  }
  
   
 .collapsed .chat-header,
 .collapsed .chat-input{
  left: 60px;
  }

  @media (max-width: 767px) {
    .chat-header {
      left: 0;
    }
  }
  
  .toggle-sidebar {
    padding: 0.25rem 0.5rem;
  }
  
  .messages {
    flex: 1;
    padding: 1rem;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 54px;
    margin-bottom: 80px;
    padding-bottom: 20px; 
  }
  
  .empty-state {
    text-align: center;
    color: #6c757d;
    padding: 2rem;
  }
  
  .message {
    max-width: 70%;
    padding: 1rem;
    border-radius: 1rem;
    background: white;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  }
  
  .message.ai {
    margin-right: auto;
    background: white;
  }
  
  .message-content {
    margin-bottom: 0.5rem;
    word-wrap: break-word;
  }
  
  .message-time {
    font-size: 0.75rem;
    color: rgba(0,0,0,0.5);
    text-align: right;
  }
  
  .message.user .message-time {
    color: rgba(255,255,255,0.8);
  }
  /* .chat-input {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 260px;
    padding: 1rem;
    background: white; 
  } */
  
  @media (max-width: 767px) {
    .chat-input {
      left: 0;
    }
  }
  .land-ai-model{
    cursor: pointer; 
  } 
  .land-ai-model:hover img{ 
    box-shadow: -1px -1px 18px 1px #8f3dfd; 
    box-sizing: content-box;
  }
  .file-previews {
    margin-bottom: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  
  .file-badge {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.25rem 0.5rem;
    background: #e9ecef;
    border-radius: 0.25rem;
  }
  
  .remove-file {
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.2s;
  }
  
  .remove-file:hover {
    opacity: 1;
  }
  
  .file-attachment {
    margin-bottom: 0.5rem;
  }
  
  .attachment-preview {
    max-width: 200px;
    border-radius: 0.5rem;
  }
  
  .attachment-link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: inherit;
    text-decoration: none;
  }
  
  .loading {
    text-align: center;
    color: #6c757d;
    padding: 1rem;
    font-style: italic;
  }
  
  .error-alert {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 1000;
  }

  /*************/
  .recent-chats-container {
    padding: 2rem;
  }
  .recent-chats-container table{
    font-size: 14px;
    border: 1px solid #e3e3e3;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
  }
  
  .back-button {
    display: flex;
    align-items: center;
    gap: 0.5rem; 
    text-decoration: none;
  }
  
  .actions {
    display: flex;
    gap: 1rem;
  }
  
  .chat-title {
    cursor: pointer;
    color: #0d6efd;
  }
  
  .chat-title:hover {
    text-decoration: underline;
  }
  
  tr.selected {
    background-color: #f8f9fa;
  }
  
  .delete-btn {
    color: #dc3545;
  }
  
  .delete-btn:hover {
    color: #bb2d3b;
  }
  
  /**************************/
  /* Code block styles */
.code-block-wrapper {
  margin: 1rem 0;
  border-radius: 4px;
  overflow: hidden;
  background: #1e1e1e;
}

.code-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background: #2d2d2d;
  color: #fff;
}

.language-tag {
  font-size: 0.8rem;
  text-transform: uppercase;
  color: #999;
}

.copy-button {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  padding: 0.2rem;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.copy-button:hover {
  opacity: 1;
}

/* Table styles */
.custom-table-wrapper {
  overflow-x: auto;
  margin: 1rem 0;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
  background: white;
}

.custom-table th,
.custom-table td {
  padding: 0.75rem;
  border: 1px solid #dee2e6;
}

.custom-table th {
  background: #f8f9fa;
}

/* Typing effect styles */
.typing-effect {
  cursor: pointer;
}

.typing-cursor {
  display: inline-block;
  width: 2px;
  height: 1em;
  background-color: currentColor;
  margin-left: 2px;
  animation: blink 1s infinite;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

/*********************/
/* Add these styles to your AIChat.css file */

.message-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  margin-top: 0;
}

.message-time {
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.5);
}

.message.user .message-time {
  color: rgba(255, 255, 255, 0.8);
}

.copy-button {
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.copy-button:hover {
  opacity: 1;
  background: rgba(0, 0, 0, 0.05);
}
.text-green-500 {
  color: #10b981;
}

/*********/
/* Add these styles to your AIChat.css file */

.code-highlighter {
  margin: 1rem 0;
  border-radius: 6px;
  overflow: hidden;
  background: #282c34;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.code-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background: #21252b;
  color: #abb2bf;
  border-bottom: 1px solid #181a1f;
}

.language-label {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, monospace;
  font-size: 0.875rem;
  color: #abb2bf;
  text-transform: lowercase;
}

.copy-code-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.5rem;
  background: transparent;
  border: none;
  border-radius: 4px;
  color: #abb2bf;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.copy-code-button:hover svg,
.copy-code-button:hover span
{
  color:#d0d1d2;
}

.copy-code-button span {
  font-size: 12px;
}
.copy-code-button svg {
  width: 12px;
}
.icon-success {
  color: #98c379;
}

/* Style adjustments for the code block itself */
.code-highlighter pre {
  margin: 0 !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

/* Adjustments for when code block is inside a message */
.message .code-highlighter {
  margin: 0.5rem 0;
}

/* Dark mode adjustments if needed */
@media (prefers-color-scheme: dark) {
  .code-highlighter {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
}

/********************/
.typing-effect {
  display: inline-block;
  position: relative;
}

.typing-cursor {
  display: inline-block;
  width: 2px;
  height: 1em;
  background-color: currentColor;
  margin-left: 2px;
  vertical-align: middle;
  animation: blink 1s infinite;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.ai-text-line {
  margin: 0.5rem 0;
}

/* Add a hover hint for incomplete typing */
.typing-effect:not(.complete):hover {
  background-color: rgba(0, 0, 0, 0.05);
}

/************************************************/
.chat-input .input-group textarea.form-control {
  border-radius: 0.375rem;
  transition: height 0.1s ease-out;
  min-height: 45px;
  background:transparent !important;
  margin-right: 9px;
  border: none;
}

.chat-input .input-group {
  align-items: flex-end;
  background: rgb(233 236 239);
  padding: 8px;
  border-radius: 15px;
  /* background: #fff;
  border: 1px solid #b7b7b7;
  box-shadow: 0 0 0px 5px #f1f1f1; */
}
.chat-input .input-group .btn {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center; 
  border-radius: 15px 15px 15px 0px !important;
  width: 45px;
  padding: 0px;
  z-index: 1;
}
.chat-input .input-group .btn svg{
  font-size: 50px !important;
}
.chat-input .chat-attachment{
  background:transparent !important;
  border: none;
 }
 .chat-input .chat-attachment:hover{
  background:transparent !important;
  border: none;
  box-shadow: none;
 }
.chat-input .chat-attachment svg{
 fill:none;
}
.file-badge.badge.bg-primary {
  background: #e9ecef !important;
  color: #525252;
  font-size: 10px;
}
.chat-input .remove-file {
  padding: initial;
  border: none;
  background: none;
  color: #7e7e7e;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.messages{
  padding: 4.3rem 1rem 1rem;
}
.message{
  box-shadow: none;
  border: none;
}
.messages,
.chat-input-wrapper {
  max-width: 790px;
  margin: 0 auto;    
  width: -webkit-fill-available;
}
.message.ai {
  max-width: 100%;
}
.message.user {
  max-width: fit-content;
  margin-left: auto;
  background: #e9ecef; 
}
.message.user .message-time{
  color: #535353;
}
.chat-container .code-header{
  padding: 5px 16px;
}
span.model-name {
  font-size: 14px;
  font-style: normal; 
  margin-bottom: 10px;
  display: inline-block;
}
.message.user p {
  margin: 0;
}
.message.user .message-content {
  margin-bottom: 0; 
}

.messages {
  overflow: inherit;
}

.chat-header {
  position: fixed;
  left: 260px;
  right: 0;
  /* border-bottom: 1px solid #ebebeb; */
  padding: 0px 30px 0;
  background: #fff;
  top: 0;
  z-index: 2;
  height: 53px;
  display: flex;
  align-items: center;
  align-content: center;
}

.delete-btn svg {
  fill: none !important;
}
button.btn svg{  
  fill: none !important;
}
.customEditorBtns button svg {
  fill: #000 !important;
  background: transparent;
}
.customEditorBtns button:hover { 
  background: transparent;
}
.agent-selector-toggle svg{
  stroke: #4701ff !important;
}
.system-prompt{
  display: none;
}
.system-prompt-btn {
  height: auto;
  width: 30px;
  margin-top: 20px;
  margin-left: 10px;
  border: none;
  background: #fff;
  color: #333;
  box-shadow: none;
  display: flex;
  justify-content: center;
  padding: 11px 0;
}
.system-prompt-btn:hover, .system-prompt-btn:focus, .system-prompt-btn:active:focus{ 
  border: none;
  background: #fff;
  color: #3417b4; 
  box-shadow: none !important;
}
.chat-head-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
}
.chat-select-model {
  display: flex;
}
.agent-name {
    /* background: #f0f0f0; */
    height: fit-content;
    padding: 13px 12px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
}
.font-bold{
  font-weight: 600;
}
.agent-name .agent-icon {
  width: 20px;
  margin-right: 5px;
  color: #4701ff;
}

/* Message Styling */
.message *{
  margin: 0;
  padding: 0;
  font-size: 15px; 
}
.message p, .message ul, .message ol, .message h2, .message h3, .message h4, .message h5, .message h6, .message hr{  
  margin-top: 1em;
}
hr:not([size]) {
  height: 0.05rem;
}
.message {
  max-width: 80%;
  margin-bottom: 1rem;
  position: relative;
}
.message p, .message li, .message strong {
  line-height: 1.7;
  font-size: 15px !important; 
}
.user-message .message-content {
  background-color: #007bff;
  color: white;
  padding: 1rem;
  border-radius: 1rem;
  border-bottom-right-radius: 0.25rem;
}

.ai-message .message-content {
  background-color: #f8f9fa;
  padding: 1.5rem;
  border-radius: 1rem;
  border-bottom-left-radius: 0.25rem;
  border: 0;
}

/* Code Block Styling */
pre, .ai-message .message-content pre {
  background-color: #000;
  color: #fff;
  padding: 1rem;
  border-radius: 0.5rem;
  overflow-x: auto; 
  white-space: pre-wrap;
}

.code-block-container {
  position: relative;
  margin: 1rem 0;
}

.code-language {
  color: #bbbbbb;
  font-size: 0.85rem;
  font-family: monospace;
}

/* Copy Button Styling */
.copy-button, .message-copy-button {
  background: transparent;
  border: none;
  color: #6c757d;
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}


.user-message .copy-button,
.user-message .message-copy-button {
  color: rgba(255, 255, 255, 0.7);
}

.user-message .copy-button:hover,
.user-message .message-copy-button:hover {
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
}
.message-copy-button:hover {
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
}
.copy-button:hover {
  color: #c2c2c2;
}
/* Markdown and List Styling */
.message-content { 
  font-size: 15px !important;
  position: relative;
}

.message-content ul, .message-content ol {
  list-style: inherit;
  margin-left: 20px;
}

.message-content h1, .message-content h2, .message-content h3,
.message-content h4, .message-content h5, .message-content h6 { 
  font-weight: bold;
  margin-bottom: 0px;
}

.message-content h1 { font-size: 24px; }
.message-content h2 { font-size: 22px; }
.message-content h3 { font-size: 20px; }
.message-content h4 { font-size: 18px; }
.message-content h5 { font-size: 16px; }
.message-content h6 { font-size: 14px; }

.user-markdown {
  background-color: #414141;
  color: white !important;
  border-bottom-right-radius: 0.25rem;
}

/* Code Block Header Styling */
.code-block-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 0;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom: 1px solid #333;
  margin: -20px 0 20px;
  color: rgb(152, 152, 152);
  text-transform: lowercase;
}

.copied-text {
  animation: fadeIn 0.2s ease-in-out;
  color: #4CAF50;
}
.message-content :where(.message-content>:first-child):not(:where([class~=not-prose],[class~=not-prose] *)) {
  margin-top: 0 !important;
}

/* Add these styles to your AIChat.css */

.chat-layout {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.landing-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 364px);
  padding-top: 20px;
}

.chat-input-centered {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  position: relative;
}

.chat-input-bottom {
  position: sticky;
  bottom: -16px;
  left: 0;
  right: 0;
  background: white;
  padding: 1rem;
  padding-bottom: 24px;
}
.main-container.collapsed .chat-input-bottom{
  left: 0px;
}
.agent-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.agent-card { 
  padding: 0.3rem;
  border-radius: 0.5rem;  
  transition: all 0.2s;
}

.agent-card:hover {
  /* background: #e9ecef; */
  transform: translateY(-2px);
}
.agent-card p{
  padding: 5px 0 12px !important;
}
.chat-input-alert{
  font-size: 13px;
  top: -43px;
  height: auto;
  padding: 10px 20px !important;
}
.chat-input-alert .btn-close{
  height: auto;
  padding: 18px 20px !important;
}
@media (max-width: 767px) {
  .chat-input-bottom {
    left: 0;
  }
  
  .landing-content {
    padding: 1rem;
  }
}

.landing-mode .messages {
  margin-top: 0;
  margin-bottom: 0;
  overflow: visible;
  padding: 4rem 0;
}

 

.agent-content .agents { 
  border: 1px solid var(--border-color) ;
  padding: 3px 8px;
  border-radius: 5px;
  font-size: 13px;
  cursor: pointer;
  color: var(--text-muted) ;
}

.agent-content {
  display: flex;
  column-gap: 15px;
}
.agent-content .agents:hover {
  background-color:rgb(255 255 255 / 18%);
}

/************/
.empty-state {
  color: #6c757d;
  padding: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
}
.thinking .text{
  font-size: 13px;
  margin-left: 10px;

}

.empty-chat-message {
  height: calc(100vh - 250px); 
  display: flex;
  justify-content: center;
  align-items: center;
}
.landing-title {
  margin: 0;
  font-size: 28px;
  font-weight: 700;
}
.landing-des{
  margin: 0;
  font-size: 16px !important;
}
.land-card h2 {
  font-size: 18px !important;
  font-weight: 600;
}
/* Animated gradient */
.animated-gradient-text {
  background: linear-gradient(215deg, #4701ff, #4f24ba, #782bca, #a74ef5, #0000ff);
  background-size: 200% auto;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient 5s linear infinite;
}
/***/
.agents-collection{
  width: 100%;
}
.agents-collection .agent-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  padding: 0;
  flex-wrap: wrap; 
}
.logos-wrap {
  display: flex;
  gap: 40px;
}
.ai-models-logos{
  display: flex;
}
.ai-models-logos {
  width: 100%;
  float: left;
}
.logo-items {
  color: #797979;
  width: 30px;
  height: 30px;
  font-size: 11px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo-items img {
  width: 100%;
  border-radius: 50%;
}
.ai-models-logos {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  flex-direction: column;
}
.ai-models-logos h6 {
  padding: 10px 0 15px;
  font-size: 14px;
  color: #797979;
  font-weight: 400;
}
.logo-items img {
  width: 100%;
  border-radius: 50%; 
}
.logo-items:hover img {
  filter: none;
  opacity: 1;
}


/* Common drawer styles */
.offcanvas {
  width: 400px !important;
}

/* Model Selector Drawer */
.model-provider-section {
  margin-bottom: 1.5rem;
}

.provider-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
}

.model-item {
  padding: 1rem;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.model-item:hover {
  background-color: var(--hover-bg);
}

.model-item.active {
  background-color: var(--hover-bg);
  border-left: 3px solid var(--primary-color);
}

.model-name {
  font-weight: 500;
  font-size: 14px;
}

.model-level {
  font-size: 12px;
  color: var(--text-muted);
}
.provider-icon{
  border-radius: 50%; 
}
/* Agent Selector Drawer */
.agent-section {
  margin-bottom: 2rem;
}

.section-title {
  font-size: 13px;
  font-weight: 600;
  color: var(--text-muted);
  text-transform: uppercase;
  margin-bottom: 1rem;
  padding: 0 0.5rem;
}

.agent-item {
  display: flex;
  align-items: flex-start;
  gap: 5px;
  padding: 1rem;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.agent-item:hover {
  background-color: var(--hover-bg);
}

.agent-item.active {
  background-color: var(--hover-bg);
  border-left: 3px solid var(--primary-color);
}

.agent-icon {
  color: var(--text-muted);
  flex-shrink: 0;
}

.agent-info {
  flex: 1;
  min-width: 0;
}

.agent-name {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0.25rem;
}

.agent-description {
  font-size: 12px;
  color: var(--text-muted);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Dark mode support */
[data-theme='dark'] .offcanvas {
  background-color: var(--bg-primary);
  border-color: var(--border-color);
}

[data-theme='dark'] .model-item:hover,
[data-theme='dark'] .agent-item:hover {
  background-color: var(--hover-bg);
}

/* Mobile responsiveness */
@media (max-width: 576px) {
  .offcanvas {
    width: 100% !important;
  }
}
.model-selector-btn,
.agent-selector-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  font-size: 14px;
  color: var(--text-color);
  border: 1px solid var(--border-color);
  background: var(--bg-primary);
}

.model-selector-btn:hover,
.agent-selector-btn:hover {
  background: var(--hover-bg);
  border-color: var(--border-color);
  color: var(--text-color);
}

/***/
.selector-drawer .nav-tabs {
  border-bottom: 2px solid #dee2e692;
  margin-bottom: 1rem;
}

.selector-drawer .nav-tabs .nav-link {
  border: none;
  color: #787878;
  padding: 0.5rem 1rem;
}
.selector-drawer .nav-tabs .nav-link{
  border-radius: 0;
}
.selector-drawer .nav-tabs .nav-link.active {
  color: #4701ff;
  border-bottom: 2px solid #4701ff;
  background: transparent;
}

.combined-selector-btn {
  padding: 8px 12px;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  min-width: 120px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.combined-selector-btn:hover {
  background-color: #f8f9fa;
}

.model-item, .agent-item {
  padding: 12px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.model-item:hover, .agent-item:hover {
  background-color: #f8f9fa;
}

.model-item.active, .agent-item.active {
  background-color: #f0f0f0;
}

.model-icon {
  border-radius: 50%;
  object-fit: cover;
}

.section-title {
  font-size: 0.875rem;
  font-weight: 600;
  color: #666;
  margin: 1rem 0 0.5rem;
}

.agent-info {
  margin-left: 12px;
}

.agent-name {
  font-weight: 500;
}

.agent-description {
  font-size: 0.875rem;
  color: #666;
}
.selector-drawer .agent-description{
  margin-bottom: 0;
}
.selector-drawer .agent-icon {
  width: 35px;
  height: 35px;
}

/****/
.chat-model-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 260px;
  height: 60px;
  background: var(--bg-primary);
  border-bottom: 1px solid var(--border-color);
  z-index: 999;
  padding: 0 1rem;
  display: flex;
  align-items: center;
}

.collapsed .chat-model-header {
  left: 60px;
}

.model-selector-btn {
  height: 36px;
  border-color: var(--border-color);
  background: transparent;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 12px;
  font-size: 14px;
}

.model-selector-btn:hover {
  background: var(--hover-bg);
}


.ai-model-name{
  font-size: 14px; 
    font-weight: 400;
}






/*****************/
.all-tools{
  font-weight: 600;
}
.chat-layout {
  position: relative;
}
.input-group.input-form-wrapper {
  display: flex;
  flex-direction: column;
  align-content: space-between;
}
.chat-textarea{
  width: 100%;
}
.input-controlls {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-top: 5px;
    margin-bottom: -5px;
}


.chat-input .input-group .btn {
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px 15px 15px 0px !important;
  width: 35px;
  padding: 0px;
  z-index: 1;
  margin-right: 15px;
}
.chat-input .input-group textarea.form-control{
  width: 100%;
}
.combined-selector-btn {
  font-size: 14px !important;
  width: 151px !important;
  display: flex;
  align-items: center;
  padding: 0 8px !important;
  margin: 0;
  height: 30px !important;
}
.combined-selector-btn .model-name {
  margin:0 !important;
  padding: 0;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/****************************/
.chat-history-drawer {
  width: 350px !important;
}

.history-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  padding: 0;
  margin-right: 15px;
  border-radius: 15px !important;
  background: transparent !important;
  border: none;
  color: #666;
}

.history-btn:hover {
  background: #f0f0f0 !important;
  color: #333;
}

.chat-history-drawer .offcanvas-title {
  font-size: 16px;
  font-weight: 600;
}

.chat-history-drawer .chat-list-section {
  padding: 0;
}

.chat-history-drawer .chat-list {
  max-height: calc(100vh - 150px);
  overflow-y: auto;
}

.chat-history-drawer .chat-item {
  padding: 6px 10px;
  border-radius: 6px;
  margin-bottom: 0;
  transition: background-color 0.2s;
  display: flex;
  font-size: 13px;
  align-items: flex-start;
  justify-content: space-between;
  gap:5px;
}

.chat-history-drawer .chat-item:hover {
  background: #f8f9fa;
  cursor: pointer;
}

.chat-history-drawer .chat-item.active {
  background: var(--hover-bg);
  color: var(--primary-color);
}

.chat-history-drawer .view-all-btn {
  margin-top: 16px;
  text-align: center;
  width: 100%;
  color: var(--primary-color);
}
button.delete-chat:hover {
  color: red;
}
button.delete-chat {
  padding: 0;
  color: gray;
}
.chat-input .input-group textarea.form-control {
  width: 100%;
  padding: 10px !important;
}
.input-group.input-form-wrapper .form-control:focus {
  background: #ffffff47 !important;
  box-shadow: none !important;
}

.selector-drawer .nav-tabs{
  /* display: none; */
}

button.new-chat-btn, .history-btn {
  width: 97px !important;
  font-size: 13px;
  font-weight: normal;
}
.chat-input .input-group .btn svg {
  font-size: 29px !important; 
}
.chat-input .input-group .btn{
  border-radius: 30px !important;
}
.combined-selector-btn, button.new-chat-btn, .history-btn{
  color: #575757;
  border-radius: 15px !important;
  padding: 5px 10px !important;
  height: 30px !important;
}
.combined-selector-btn:hover, button.new-chat-btn:hover, .history-btn:hover{
  color: #333;
  box-shadow: none;
  background-color: #f8f9fa !important;
}
