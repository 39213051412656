/* Message layout */
.message {
    display: flex;
    gap: 12px;
    margin-bottom: 1.5rem;
    max-width: 80%;
  }
  
  .message.ai {
    max-width: 100%;
  }
  
  .message.user {
    max-width: fit-content;
    margin-left: auto;
    flex-direction: row-reverse;
    margin-right: 35px !important;
  }
  
  .message-wrapper {
    flex: 1;
    max-width: calc(100% - 48px);
  }
  
  .message.user .message-wrapper {
    flex: 1;
    max-width:100%;
  }
  /* Avatar styles */
  .message-avatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    font-weight: 500;
    font-size: 14px;
    overflow: hidden;
    background-color: #f0f0f0;
  }
  
  .model-avatar-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* User specific styles */
  .message.user .message-avatar {
    background-color: #e9ecef;
    color: #333;
  }
  
  .message.user .message-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  
  
  /* Agent specific styles */
  .message.ai.agent .message-avatar {
    background-color: #f0f0f0;
    color: #4701ff;
    padding: 8px;
  }
  
  /* .message.ai.agent .model-name {
    color: #4701ff;
  } */
  
  /* Message content styles */
  .message-content {
    padding: 1.5rem;
    border-radius: 1rem;
    border-bottom-left-radius: 0.25rem; 
  }
  
  .message.user .message-content {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 1rem;
    margin-bottom: 0;
  }
  
  /* Footer styles */
  .message-footer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    margin-top: 4px;
  }
  
  .model-name {
    font-size: 13px; 
    margin-right: 15px !important;
    text-transform: capitalize;
  } 
  .message.ai .copy-button {
    font-size: 13px;
    font-style: italic;
    opacity: 1;
  }  
  .copy-button {
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
  }
  
  .copy-button:hover {
    color: #000; 
    background: transparent;
  }
  
  /* Attachment styles */
  .file-attachment {
    margin-bottom: 0.5rem;
  }
  
  .attachment-preview {
    max-width: 200px;
    border-radius: 0.5rem;
  }
  
  .attachment-link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: inherit;
    text-decoration: none;
  }
  
  /* Content typography */
  .message * {
    margin: 0;
    padding: 0;
    font-size: 0.9rem;/*15px*/
  }
  
  .message p, .message ul, .message ol, 
  .message h2, .message h3, .message h4, 
  .message h5, .message h6 {
    margin-top: 1em;
  }

  .message ul ul, .message ul ul ul {
    margin: 0;
    margin-left: 20px;
  }
  
  .message p, .message li, .message strong {
    line-height: 1.7;
    font-size: 0.9rem !important;
  }
  
  /* Code block styles */
  pre, .message-content pre {
    background-color: #000;
    color: #fff;
    padding: 1rem;
    border-radius: 0.5rem;
    overflow-x: auto;
    white-space: pre-wrap;
    margin-top: 1em;
  }
  
  /* Responsive styles */
  @media (max-width: 767px) {
    .message-avatar {
      width: 32px;
      height: 32px;
      font-size: 12px;
    }
    
    .message {
      gap: 8px;
    }
    
    .message-wrapper {
      max-width: calc(100%);
    }
    
    .message-content {
      padding: 0;
    }
    .message.ai {
      max-width: 100%;
      display: flex  ;
      flex-direction: column;
   }
   .message.user {
        margin-right: 0px !important;
    }
  }
  .message.ai .message-content {
    padding-bottom: 10px;
}
.chat-container .message{
    padding: 8px 15px 8px;
    margin-bottom: 0;
}
.message.ai.agent .message-avatar {
    background: #212529;
    color: #fff;
}

/***/
.copy-button, .retry-button {
  background: none;
  border: none;
  color: #626262;
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.copy-button:hover, .retry-button:hover {
  color: #000; 
}

/* Dark mode support */
[data-theme='dark'] .copy-button,
[data-theme='dark'] .retry-button {
  color: #ababab;
}

[data-theme='dark'] .copy-button:hover,
[data-theme='dark'] .retry-button:hover {
  color: #ffffff; 
}

 /* Add these styles to your existing ChatMessage.css */

.first-agent-message {
  text-align: center;
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
}

.agent-intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;
}

.agent-intro-icon {
  color: #6366f1;
  margin-bottom: 1rem;
}

.agent-intro-name {
  font-size: 1.5rem;
  font-weight: 600; 
  margin: 0;
}

.agent-intro-message {
  font-size: 1rem;
  line-height: 1.5; 
}
span.footer-model-name {
  font-size: 14px;
  font-style: italic;
  margin-right: 10px;
  opacity:0.8;
}
/* Your existing message styles remain unchanged */