.card-grid-row{
    display: flex; 
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
}
.card-grid-row .card{
    width: 31%;
    margin: 10px;
}
.card-grid-row .card:hover{
    box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
    transform: translateY(-1px);
}
.card-grid-row .card-body { 
    padding: 20px;
    width: 75%;
    padding-left: 0;
}
.card-grid-row .card p {
    color: #676767;
    line-height: 19px;
    font-size: 0.82rem !important;
}
.card-wrap{
    display: flex;
}
.card-icon{
    display: flex;
    vertical-align: middle; 
    align-items: center;
    width: 25%;
}
.card-icon i{
    margin:0 auto;
}  
.card-icon svg{
    margin: 0 auto;
    font-size: 40px;
}
.card-title.h5 {
    margin-bottom: 0;
    font-size: 1rem;
}  
.header-txt p{
    font-size: 0.75rem;
    font-weight: 400;
}
.editMode.card {
    padding: 18px 50px;
}
.wordCount{
    font-size: 12px;
}

 
.updating {
    font-size: 15px;
}
.in-text{
    font-size: 11px;
    position: relative;
    top: 7px;
}

.updating:after {
overflow: hidden;
display: inline-block;
vertical-align: bottom;
-webkit-animation: ellipsis steps(4,end) 900ms infinite;      
animation: ellipsis steps(4,end) 900ms infinite;
content: "\2026"; /* ascii code for the ellipsis character */
width: 0px;
font-size: 15px;
color:#6a3dfd;
font-weight: 700;
}

@keyframes ellipsis {
to {
    width: 1.25em;    
}
}

@-webkit-keyframes ellipsis {
to {
    width: 1.25em;    
}
}