.chat-panel .cs-main-container{
    border: none;
    height: 100%;
    display: flex;
}
.chat-layout .col-md-5{
  padding: 0;
}
.chat-layout .col-md-7{
padding-right: 0;
}
section.tool-panel.chat-section, .qTextEditor {
    display: inline-block;
    width: 100%;
    margin:0 auto;
    height: calc(100vh - 100px);
    display: flex;
}
.qTextEditor{
  margin: 0;
  height: calc(100vh - 100px);
  display: flex;
  position: relative !important;
}
.qTextEditor.card .card-body {
  padding: 0;
}

.qTextEditor .ql-editor p, .qTextEditor .ql-editor ol li, .qTextEditor .ql-editor strong, .qTextEditor .ql-editor ul li { 
  font-size: 15px!important;
  line-height: 24px;
}

.cs-main-container, .cs-chat-container, .cs-message-list{
    background-color: transparent !important;
    border:none !important;
}
.chat-panel {
    width: 100%;
}
.cs-main-container .cs-chat-container{
    padding: 30px 0 30px;
}
.cs-message-input__content-editor-wrapper, .cs-message-input__content-editor{
background-color: #fff !important;
}
.cs-message-input__tools:first-child .cs-button:first-child{
    display: none;
}
.cs-message-input__content-editor{
    font-family: inherit !important;
    font-size: 16px;
    padding: 5px;
}
.cs-message-input__tools .cs-button{
    height: 50px;
}
.cs-chat-container .cs-message-input {
    border-top: solid 1px #d1dbe3;
    border-right: solid 0px #d1dbe3;
    border-bottom: solid 0px #d1dbe3;
    border-left: solid 0px #d1dbe3;
    margin-top: auto;
    padding: 0.3em 0 0.3em 0em;
    color: rgba(0,0,0,.87);
    background-color: #fff;
    background: #fff !important;
    box-shadow: 2px 2px 2px #bbbbbb29;
    border-radius: 15px;
    /* border: 1px solid #dadada !important; */
    border: none;
    border-radius: 6px;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 25%);
    padding: 0 15px;
    margin: 0px 5px;
}
.cs-message--outgoing .cs-message__content {
    color: rgba(0,0,0,.87);
    background-color: #e3dcfd !important;
    border-radius: 0.7em 0.7em 0 0.7em !important;
}
.cs-message__content { 
    background-color: #e5f4ff !important; 
    padding: 1em 1.2em;
    margin-top:8px !important;
    border-radius: 0.7em 0.7em 0.7em 0 !important;
    font-size: .95em !important; 
    font-family: inherit !important;
}
.cs-message-list .cs-typing-indicator {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 15px;
    background-color: transparent !important;
    padding: 0.2em 0.9em !important;
    font-size: .9em;
}
.cs-button svg {
    color: #4701ff;
}
.cs-message-input__content-editor-container{
    max-height: 14em !important;
} 


.chatbot-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  }
  
  .messages-container {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 1rem;
  }
  
  .message-container {
    /* display: flex; */
    margin-bottom: 1rem;
    position: relative;
  }
  
  .user-message {
    justify-content: flex-end;
  }
  
  .assistant-message {
    justify-content: flex-start;
  }
  
  .message-bubble {
    max-width: 100%;
    padding: 0.5rem 1rem;
    border-radius: 5px; 
    font-size: 14px;
    float: left;
    width: 100%;
  }
  .message-container.assistant-message, .message-container.user-message{
    border: 1px solid #fff !important;
  }
  .message-container.assistant-message:hover { 
    border-color: #f0ebf3 !important; 
    border-radius: 5px; 
}
  
  .user-message .message-bubble {
    background-color: #e6e5ff;
    color: #333;
    white-space: break-spaces;
}
  
  .assistant-message .message-bubble {
    /* background-color: #fdf9ff; */
    background-color: #fff;
    color: #333;
    white-space: break-spaces;
  }
  
 
  
  .user-input {
    width: 100%;
    padding: 1rem;
    border: none;
    border-top: 1px solid #ddd;
    outline: none;
  }
  
  
  .user-message .copy-text-link {
    margin-left: auto;
    margin-right: 0.5rem;
  }
  

  .user-input-container {
    display: flex;
    align-items: center;
    width: 100%;
    border: 3px solid #ddd;
    border-radius: 10px;    
    margin-bottom: 0px;
  }
  
  .user-input {
    flex-grow: 1;
    padding: 1rem;
    border: none;
    outline: none;
    resize: none;
    border-radius: 12px;
  }
  .user-input-container:hover {
    box-shadow: 0px 0px 12px 0 #3333332b;
}
  .send-button {
    padding: 1rem;
    background-color: transparent;
    border: none;
    color: #007bff;
    font-size: 1.2rem;
}
  
  .send-button:hover {
    background-color: transparent;
    color: #046bd9;
  }
  
  .send-button:focus {
    outline: none;
  }
 .action-btns {
  margin-left: 0;
  color: #252525; 
  text-decoration: none;
  /* position: absolute; */
  bottom: -35px;
  font-size: 13px;
  padding: 5px 10px 4px;
  border-radius: 5px;
  left: -7px;
  right: 2px;
  text-align: right;
  /* background: linear-gradient(0deg, rgb(215 212 255) 0%, rgb(255 255 255 / 47%) 100%); */
  background-color: #f4eff6;
  display: flex;
  justify-content: space-between;
  z-index: 99;
  border-radius: 0 0 5px 5px;
  padding-right: 10px;
  margin-top: -2px;
}
.word-character-count{
  line-height: 32px;
  color: #525f7fc9;
  padding-left: 5px;
  font-size: 12px;
  font-weight: 600;
  font-style: italic;
}
.word-character-count .words{
  margin-right: 20px;
}
.action-btns .btn, .btnTry {
  background-color: #f4eff6;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 10px;
  border-radius: 4px;
  color: #525f7f;
  border-color: #f4eff6; 
  margin-top: 1px;
  /* border: 1px solid #7a2ef4; */
}
.btnTry {
  margin-bottom: 8px;
  font-size: 12px;
}
.action-btns .btn svg {
  width: 12px;
}
.action-btns .btn:hover svg {
  fill: #7a2ef4;
}
.action-btns .btn:hover {
  border-color: #7a2ef4; 
  color: #7a2ef4;
  box-shadow: none;
}
.disabled-button svg{
  color: #a9a9a9; 
}
.qTextEditor .ql-toolbar.ql-snow {
  background: transparent !important;
}
.qTextEditor .ql-editor{
  height: calc(100vh - 144px);
}
.user-input::placeholder {
  color: #a9a9a9; /* Change this value to a lighter gray if needed */
  opacity: 1; /* Required for Firefox */
}


.typing-indicator { 
  text-align: left;
  font-size: 13px;
  color: #4701ff;
  font-style: italic;
  line-height: 0;
}
@keyframes dotPulse {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.typing-indicator .dot {
  animation: dotPulse 1s infinite;
  font-size: 20px;
  padding: 0 1px;
}

.typing-indicator .dot:nth-child(2) {
  animation-delay: 0.3s;
}

.typing-indicator .dot:nth-child(3) {
  animation-delay: 0.6s;
}

.smartChart {
  text-align: center;
  max-width: 78%;
  margin: 0 auto;
  opacity: 0.70;
}
.smartChart h3 {
  font-weight: 700;
  font-size: 28px;
}
.smartChart strong {
  font-weight: 600;
}
.smartChart p { 
  font-size: 14px !important;
  line-height: 28px;
} 
.user-info-wrap {
  display: flex;
  justify-content: space-between;
  padding: 8px 14px 0;
}
.chat-layout .customEditorBtns {
  width: 360px;
  position: absolute;
  right: 15px;
  top: 7px;
  z-index: 9;
  background: #ffffff;
  float: right;
  display: flex;
  justify-content: space-around;
}
.customEditorBtns button svg {
  fill: #4701ff !important;
}
.sub-btn{
  background-color: transparent;
}



.qTextEditor .ql-snow .ql-editor h1 {
  font-size: 1.9em;
  font-weight: 800;
  margin-bottom: 15px;
}
.qTextEditor .ql-snow .ql-editor h2 {
  font-size: 1.6em;
  font-weight: 600;
  margin-bottom: 15px;
}
.qTextEditor .ql-snow .ql-editor h3 {
  font-size: 1.4em;
  font-weight: 600;
  margin-bottom: 15px;
}
.qTextEditor .ql-snow .ql-editor h4 {
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 15px;
}
.qTextEditor .ql-snow .ql-editor h5 {
  font-size: 1.0em;
  font-weight: 600;
  margin-bottom: 15px;
}
.qTextEditor .ql-snow .ql-editor h6 {
  font-size: 0.9em;
  font-weight: 600;
  margin-bottom: 15px;
}
.qTextEditor .ql-editor p, .ql-editor ul li, .ql-editor strong, .ql-editor ol li {
  font-size: 16px !important;
  color: #32325d !important;
  line-height: 26px;
}

.qTextEditor .subHeading[placeholder]:empty:before {
  content: attr(placeholder);
  color: #cfcfcf; 
}
.qTextEditor [contenteditable=true]:empty:before {
  content: attr(placeholder);
  pointer-events: none;
  display: block; /* For Firefox */
}
.qTextEditor .ai-content:active, .qTextEditor .ai-content:focus, .qTextEditor .ai-content:focus-within {
  border-color: #9b9b9b8a !important;
  box-shadow: 0 0 0px 4px #6a3dfd21 !important;
}