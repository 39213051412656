.editContent{
    overflow-x: auto;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
} 
.flexy-template .ai-board-body {
    border-radius: 0 0 6px 6px;
    background: #fff;
    padding-top: 0;
    min-height: calc(100vh - 170px);
    overflow: hidden;
    height: calc(100vh - 170px);
    overflow-y: none; 
    padding: 0;
}
.qullTextEditor{
    width: 100%;
    height: calc(98.5% - 40px);
} 
.ql-container.ql-snow, .ql-toolbar.ql-snow {
    border: 0px solid #e2e8ef !important; 
}
.ql-toolbar.ql-snow{
    border-radius: 15px 15px 0 0 !important;
    border-bottom: 0px !important;
}
.ql-container.ql-snow{
    border-radius: 0 0 15px 15px !important;
}
.ql-editor {
    /* background: #f8fbff; */
    border-radius: 0 0 15px 15px !important;
}

.ql-toolbar.ql-snow { 
    background: #fff !important;
    border-radius: 0 !important;
    padding: 8px 15px !important;
    border-bottom: 1px solid #e6e8eb !important;
    /* border-top: 1px solid #e6e8eb !important; */
}
.ql-editor{
    padding: 20px 25px !important;
    text-indent: 0 !important;
    margin-left: 0 !important;
    padding-left: 0 !important;
}

/**Layout change**/
.flexyWrap .tool-header{
    /* display: none; */
    /* margin-top:0; */
}
.flexyWrap input#brandName {
    font-weight: 600;
}
.flexyWrap .column:last-child {
    max-width: 1500px;
    padding: 20px 66px;
    position: relative;
    width: 100%;
}
button.accordion-button {
    font-weight: 600;
    font-family: system-ui;
    color: #212529;
    padding: 10px 12px;
    font-size: 0.875rem;
}
.flexyWrap .ai-board-header.card-title.h5 {
    margin-bottom: 0;
    font-size: 1.2rem;
    padding: 15px 24px;
    font-weight: bold;
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
}
.flexyWrap .template-title{
    flex-grow: 1;
    display: none;
}
.flexyWrap .ql-editor p, .flexyWrap .ql-container{
    font-size: 15px !important;
}
.customEditorBtns {
    width: 160px;
    position: absolute;
    right: 0;
    top: 10px;
    z-index: 9;
    background: #fff;
}
/* .customEditorBtns button {
    border: 1px solid #cdcdcd;
}  */
.customEditorBtns .btn-outline-secondary:disabled svg { 
    fill: #727272 !important;
}
.customEditorBtns button svg{
    fill: #000 !important;
}
/*Tooltip*/
.wktooltip {
    position: relative;
    display: inline-block; 
  }
  
  .wktooltip .wktooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #4c4c4c;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
    font-size: 12px;
    font-weight: normal;
  }
  
  .wktooltip .wktooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #4c4c4c transparent transparent transparent;
  }
  
  .wktooltip:hover .wktooltiptext {
    visibility: visible;
  }
 .tool-panel.flexy-template .section.tool-panel .ai-board-body{
    border-radius: 6px;;
  }
  .flexyWrap section.tool-panel .ai-board .card-body {
    padding: 0 1.5rem 0;
}
  .infoTxt{
    position: relative;
    top: -13px;
    text-align: right;
    display: block;
    font-style: italic;
  }

  .emoji{
    font-size: 34px;
  }
  .caption {
    float: left;
    width: 100%;
    margin: 5px 0 5px 0;
    font-size: 14px;
}
.form-label{
    font-size: 1rem;
}
 
.form-select{
    font-size: 15px;
}
.tool-heading{
    display: flex;
    justify-content: space-between;
}
.favWrap{
    float: right;
}
.favBtn {
    border: 1px solid #dddddd;
    padding: 3px 4px;
    opacity: 0.5;
    top: 5px;
    filter: grayscale(100%);
}
button.favBtn.active {
    opacity: 0.7;
    filter: grayscale(0);
}

.aiToolsEditor .ql-snow .ql-editor h1,
.aiToolsEditor .ql-snow .ql-editor h2,
.aiToolsEditor .ql-snow .ql-editor h3,
.aiToolsEditor .ql-snow .ql-editor h4,
.aiToolsEditor .ql-snow .ql-editor h5,
.aiToolsEditor .ql-snow .ql-editor h6 {
    font-weight: bold;
    margin-bottom: 10px;
}

.aiToolsEditor .ql-snow .ql-editor h1 {
    font-size: 1.4em;
}

.aiToolsEditor .ql-snow .ql-editor h2,
.aiToolsEditor .ql-snow .ql-editor h3{
    font-size: 1.1em;
}

.aiToolsEditor .ql-snow .ql-editor h4,
.aiToolsEditor .ql-snow .ql-editor h5,
.aiToolsEditor .ql-snow .ql-editor h6 {
    font-size: 1em;
}

.aiToolsEditor .ql-snow .ql-editor p {
    font-size: 15px !important;
    margin-bottom: 10px;
    color:#525f7f !important; 
}
.aiToolsEditor .ql-editor ul li, .aiToolsEditor .ql-editor strong, .aiToolsEditor .ql-editor ol li {
    font-size: 15px !important;
    color:#525f7f !important; 
    line-height: 20.5px !important; 
}
span.inputNote {
    font-size: 10px;
    float: right;
    padding: 5px;
}
@media (max-width: 576px){
      .column:last-child {
          width: 80%;
          padding:20px !important;
          position: relative;
          flex-grow: 1;
      }
      .backToDashboard { 
          margin-top: -20px;
      }
      .blog-post-projects table.myTemplates tr td:nth-child(3),
      .blog-post-projects table.myTemplates tr th:nth-child(3) {
          display: block;
          text-align: right;
          width: 100%;
      }
      .in-text { 
           top: 0px; 
      }
      .flexyWrap .ai-board-header.card-title.h5{
          display: block;
      }
      .flexyWrap .template-title { 
          text-align: left;
          display: block;
          width: 100%;
          margin-bottom: 10px;
      }
      .customEditorBtns { 
          top: 40px;
      }
      .tool-header h2{
          font-size: 19px;
          line-height: 28px;
      }
      .tool-header > div {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
      .tool-header .svgIcon {
        height: 28px;
        width: 28px;
        float: left;
        margin-right: 12px;
        font-size: 27px;
    }
    .tool-heading {
        display: flex;
        justify-content: space-between;
        flex-grow: 1;
    }
    .flexyWrap section.tool-panel .ai-board .card-body {
        padding: 0 10px 0;
    }
}