.blog-post-wrap{
    max-width: 900px;
    margin:0 auto;
}
 .blog-steps-wrap{
    border-radius: 20px;
    overflow: hidden;
 }
 .blog-steps-wrap button.w-100.btn {
    padding: 6px 0px 6px 20px;
    text-align: left;
    min-width: 200px;
    font-size: 15px;
    text-transform: capitalize;
    font-weight: 600; 
}
.blog-steps-wrap.card{
    background-color: transparent;
    box-shadow:none;
    border:none;
}
.blog-steps-wrap .navbar{
    background-color: transparent;
    box-shadow:none;
    position: relative;
    z-index: 5;
} 
.blog-steps-wrap .navbar-nav .nav-link{
    display: flow-root;
    align-items: center; 
    padding: 0;
}
.step {
    width: 35px;
    height: 35px;
    background: #cfd5dd;
    text-align: center;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right:80px;
}
.btn {
    text-transform: capitalize;
}
.ai-content.flexbox {
    display: flex;
    padding:15px 15px 15px 20px;
    justify-content: space-between;
}
.radio-btn{
    margin-right: 15px;
    margin-top: 3px;
}
.ai-content .text-box {
    font-weight: 500 !important;
    font-size: 16px !important;
    margin-bottom: 0;
    flex-grow: 1;
}
.text-in{
    font-size: 15px !important;
    margin-bottom: 20px;
    font-weight: 500 !important;
    color: #727578;
}
.blogPost{
  font-size: 15px;
}
.blogTitle{
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
}
.blogIntro{
    margin-bottom: 20px;
}
.blogSections{
    margin-bottom: 20px;
}
.blogSections h2{
    font-size: 18px;
    font-weight: 600;
    margin-top:30px;
    margin-bottom: 20px;
}
.wizard-placeholder{    
    margin: 50px 40px;
}
.wizard-placeholder p{
    text-align: center;
    color: #aab2bf;
    margin: 0 0 30px;
    font-size: 16px !important;
}
.blog-post-wrap label.ai-content { 
    border-radius: 15px;
    padding: 15px 35px;
    width:100%;
}
.editor-main{
 width: 100%;
 padding-top: 10px;
}
.editor-main h1{
    font-size: 24px !important;
    font-weight: 800;
    text-transform: capitalize;
}
.editor-main h2{
    font-size: 22px !important;
    font-weight: 700 !important;
}
.editor-main .input {
    font-size: 16px;
}

.editor-main textarea{
    border: none;
    resize: none;
    color: #2F393E;
    min-height: 56px;
    font-weight: 400;
    font-size: 17px;
    line-height: 28px;
    letter-spacing: 0.00938em;

    overflow: hidden;
    overflow-wrap: break-word;
    height: 112px;
}

.editor-main input {
    border: none;
    font-size: 20px;
    font-weight: 500;
    color: #2F393E;
    letter-spacing: 0.00938em;
}
.editor-main .blog-title{
    background: #fff; 
    border: none;
    padding: 10px 24px 0; 
}
.editor-main .blog-title input{
    width: 100%;
    border: none;
    font-size: 20px;
    font-weight: 500;
    color: #2F393E;
    letter-spacing: 0.00938em;
}
.editor-main .blog-intro{ 
    position: relative;
    transition: all 0.3s ease;
    border-radius: 4px;
    border: none; 
    padding: 24px;
    background-color: #fff;
}
.editor-main .blog-intro textarea{  
    width: 100%;
    display: inline-block;
}
.blog-section {
    position: relative;
    transition: all 0.3s ease;
}
.section-heading{
    background: #fff;
    border: none;
    padding: 10px 24px 0;
    display: flex;
    flex-flow: column nowrap;
    transition: all 0.3s ease;
}
.section-content{
    padding: 15px 24px 24px;
    width: 100%;
    background: #fff;
    border-radius: 4px;
    flex-flow: column nowrap;
    display: flex;
    position: relative;
    min-height: 108px;
}
.contGenLoader {
    position: absolute;
    z-index: 9;
    left: 0;
    right: 0;
    display: flex;
    top: 0;
    bottom: 0;
    background: #ffffffed; 
    vertical-align: middle;
    align-items: center;
}
.contGenLoader .loader {
    margin: 0 auto 0;
    position: relative;
    top: -27px;
}
.form-control {
    font-size: 15px; 
    color: #333; 
}
.section-toolbox, .section-controls {
    display: none;
}
.blog-section .section-controls {
    z-index: 3;
    position: absolute;
    left: -82px;
    top: 10px;
    padding: 0;
    border-radius: 0;
    border-right: 0;
    width: 68px;
}
.blog-section:hover .section-controls {
    display: block;
}
.blog-section .section-controls button.btn {
    background: #d8dee5;
    margin-bottom: 3px;
    padding: 4px 10px;
    display: block;
    font-size: 18px;
    font-weight: bold;
}
.nav-link.active-link .step{  
        background: #4701ff; 
        color: #fff; 
}
.wizard .nav-link.active-link{
    color:#4701ff !important;
}
.wizard a.nav-link {
    font-weight: 700;
}
.blog-post-wrap label.ai-content{
    background: #fff;
} 
.blog-section:hover .section-toolbox button{
    margin-bottom: 10px;
    height: auto;
    font-size: 13px !important;
    font-weight: normal;
    padding: 5px 5px;
    line-height: initial;
    text-align: center !important;
    display: inline
}
.blog-section:hover .section-toolbox button:last-child{
margin:0;
}
.blog-section, .editor-main .blog-intro{
    border: 3px solid #fff;
    box-sizing: initial;
    min-height: 108px;
}
.blog-section:hover{
    border: 3px solid #c2ccd7;
    box-sizing: initial;
}
.blog-section:hover:after {
    content: '';
    position: absolute;
    width: 145px;
    height: 100%;
    top: 0;
    right: -145px;
    z-index: 0;
}
.blog-section:hover::before {
    content: '';
    position: absolute;
    width: 85px;
    height: 100%;
    top: 0;
    left: -85px;
    z-index: 0; 
}
.blog-section:hover .section-toolbox {
    display: block;
    position: absolute;
    right: -150px;
    bottom: 10px;
    background-color: #fff;
    padding: 25px;
    border-radius: 0 10px 10px 0;
    border: 3px solid #c2ccd7;
    border-left:0;
    width: 150px;
    z-index: 2;
}
.customBlogTitle .textbox{
    font-size: 14px;
    font-weight: 400;
    border: #e2e8ef solid 2px;
    border-radius: 3px;
    padding: 4px 10px;
    background: #edf2f7;
}
.btn-success {
    color: #fff;
    background-color: #33c339;
    border-color: #33c339;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
}
.selectBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 0 20px;
    border-radius: 0 10px 10px 0;
    border-left: solid #e2e8ef 2px;
    margin-left: 10px;
}
.selectBtn span{
 margin-top: -13px;
}
[type="radio"]:checked, [type="checkbox"]:checked,
[type="radio"]:not(:checked), [type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label, [type="checkbox"]:checked + label,
[type="radio"]:not(:checked) + label, [type="checkbox"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666; 
}
[type="radio"]:checked + label:before, [type="checkbox"]:checked + label:before,
[type="radio"]:hover + label:before, [type="checkbox"]:hover + label:before,
[type="radio"]:not(:checked) + label:before, [type="checkbox"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #4701ff;
    border-radius: 100%;
    background: #fff;
}
[type="checkbox"]:checked + label:before,
[type="checkbox"]:hover + label:before,
[type="checkbox"]:not(:checked) + label:before {
    border-radius: 3px;
}
[type="radio"]:checked + label:after, [type="checkbox"]:checked + label:after,
[type="radio"]:not(:checked) + label:after, [type="checkbox"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #4701ff;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="checkbox"]:checked + label:after,
[type="checkbox"]:not(:checked) + label:after {
    border-radius: 3px;
}
[type="radio"]:not(:checked) + label:after,
[type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after,
[type="checkbox"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}  

.editorHeader {
    text-align: right;
    padding: 0 0 20px;
    float: left;
    width: 100%;
}

.editorHeader button, .editorHeader span{
    padding: 10px;
    font-size: 14px;
    text-transform: capitalize;
}
.editorHeader h5{
    font-weight: 700;
    margin: 9px 0 0 0 !important;
    padding: 0;
    font-size: 17px; 
    color: #616970;
}
.editorHeader svg{
    position: relative;
    top: -1px;
}
 
.editor-main textarea { 
    color: #2F393E; 
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.editableContent{
    white-space: break-spaces;
}

section.header-txt.sticky-top { 
    width: 100%;
    background: #e2e8ef;
    padding: 2px 0;
    /* top: 4.15rem; */
    z-index: 90;
    margin: 0;
    background-color: #f5f7f9;
    border-bottom: 1px solid #e9e9e9;
    background: #fff;
    float: left;
}
.navbar{
    z-index: 100;
}
.dropdown-menu {
    min-width: 9rem;
}
.dropdown-menu .dropdown-item {
    padding: 0.5rem 1rem;
    font-size: 14px;
} 
.header-txt h5{
    text-align: center;
}

.form-wizard .form-wizard-steps {
    margin: 0 auto 0;
    width: 68%;
  }
  .form-wizard .form-wizard-steps a {
    width: 23%;
    float: left;
    position: relative;
    padding: 0;
    color: #626889;
  }
  .form-wizard .form-wizard-steps a.active {
    color: #4701ff;
}
.form-wizard .form-wizard-steps a.active div {
    color: rgb(94 114 228);
}
  .form-wizard .form-wizard-steps a:last-child {
    width: 0px;
}
.form-wizard .form-wizard-steps a div{
    font-size: 13px;
    margin-top:7px; 
    text-align: center;
    display: inline-block;
}
  .form-wizard .form-wizard-steps a::before,
  .form-wizard .form-wizard-steps a::after {
    background-color: #c9d1da;
    content: "";
    height: 5px;
    left: 0;
    position: absolute;
    right: 0;
    top: 15px;
    transform: translateY(-50%);
    width: 100%;
    border-bottom: 1px solid #c9d1da;
    border-top: 1px solid #c9d1da;
}
  .form-wizard .form-wizard-steps a span {
    background-color: #c9d1db;
    border-radius: 50px;
    display: block;
    height: 30px;
    line-height: 30px;
    position: relative;
    text-align: center;
    width: 30px;
    z-index: 1;
  }
  .form-wizard .form-wizard-steps a:last-child::after {
    width: 0;
  }
  .nav-link.active,
  .form-wizard .form-wizard-steps a.activated div{
        color: #4701ff;
    }
  .form-wizard .form-wizard-steps a.active span, .form-wizard .form-wizard-steps a.activated span {
    background-color: #4701ff;
    color: #ffffff;
  }
  .form-wizard .form-wizard-steps a.active::after {
    background-color: #4701ff;
    left: 0;
    width: 60%;
    border-color: #4701ff;
  }
  .form-wizard .form-wizard-steps a.activated::after {
    background-color: #4701ff;
    width: 100%;
    left: 0;
    border-color: #4701ff;
  }
  .form-wizard .form-wizard-steps a:last-child::after {
    left: 0;
  } 
  .sec-form-head span{
    font-size: 13px;
    color: #838383;
    display: none;
  }

  .blog-post-wrap .ai-board-body{
    min-height: 295px;
    height: auto;
    overflow: revert;
  } 
.editor-icon svg{
    fill: #7046fd !important;
    display: inline-block;
    width: 20px;
}

.infoTooltip2 {
    position: absolute;
    right: -12px;
    top: 3px;
    font-size: 14px !important;
    fill: #979797;
    cursor: pointer;
}
  
  .infoTooltip2 .tooltiptext {
    visibility: hidden;
    width: 251px;
    background-color: #585858fa;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    right: 25px;
    font-size: 12px;
    font-family: monospace;
  }
  
  .infoTooltip2:hover .tooltiptext {
    visibility: visible;
    cursor: pointer;
  }
  label.ai-content.flexbox.customBlogTitle {
    border: none;
    margin-top: 50px;
}
.customBlogTitle .selectBtn{
    border: none;
    width: 200px;
    text-align: center !important;
}
.haveTitle{
    text-transform: initial;
    font-weight: 400;
}
.mobi{display: none !important;}
@media screen and (max-width: 425px) {
    label.ai-content.customBlogTitle {
        padding: 0;
    }
    .desk {
      display: none !important;
    }
    .mobi{
        display: block !important;
    }
    .ai-content.flexbox {
        display: block;
        padding: 15px;
    }
    .selectBtn{
        padding: 10px 0 0;
        border-left: 0;
        margin: 10px 0 0;
        border-radius: 0;
    }
    .section-toolbox, .section-controls {
        display: block;
    }
    .section-toolbox{
        padding-bottom: 40px;
    }
    .blog-section:hover .section-toolbox .btn-primary,
    .section-toolbox .btn-primary, .section-toolbox button,
    .blog-section:hover .section-toolbox button{
        display: block;
        height: 35px;
        margin:0 10px;
    } 
    .blog-section:hover .section-toolbox > div, .section-toolbox > div {
        display: flex;
        margin:0 10px;
    }
    .blog-section:hover .section-toolbox {
        position: initial;
        display: block !important;
        background: transparent;
        box-shadow: none;
        padding: 0;
        padding-bottom: 40px;
        width: 100%;
        margin: 0;
    } 
    .form-wizard .form-wizard-steps {
        margin: 5px auto 14px;
        width: 96%;
        padding: 0;
    }
    a.backToDashboard2 {
        font-size: 14px;
        margin: -4px;
        height: 20px;
        display: inherit;
    }
    .backToDashboard {
        border-radius: 5px;
        left: 24px;
        top: 124px;
    }
    .blog-section:hover:after{
        right: 0;
        display: none;
    }
    .editor-main .blog-intro .section-toolbox {
        padding-bottom: 20px;
        padding-top: 20px;
    }
    
    .editor-main .blog-intro .section-toolbox > div {
        padding: 0;
        margin: 0;
    }
  }
  .cursor {
    float: left;
    width: 100%;
}
.dashboardNav2 a{
    font-size: 14px;
}



.articleOutlineInput input[type="text"]{
    border: none;
    background: transparent;
    font-size: 15px;
    color: #333;

}
.articleOutlineInput input[type="text"]::placeholder {
    color: rgb(202, 202, 202);
  }
  .articleOutlineInput .ai-content.flexbox { 
    padding: 6px 14px; 
    border: 1px solid #cad1d7;
    border-radius: 0.25rem;
    background: #f8fbff;
}
.articleOutlineInput .selectBtn {
    border: none;
}
.articleOutlineInput .selectBtn button.btn svg {
    font-size: 14px;
    margin: -2px 2px 0 0;
    fill: #959595;
}
.articleOutlineInput .selectBtn button.btn:hover svg { 
    fill: #6a3dfd;
}
.articleOutlineInput .flexbox > div:first-child { 
    width: 100%;
    line-height: 30px;
    font-size: 15px;
}

.hideEditor{
    display: none;
}
.showEditor{
    display: block;
    box-shadow: none !important;
    border: 1px solid #ebebeb !important;
    border-top: none !important;
    border-radius: 0 !important;
    margin-top: -1px !important;
}
.article-post .qullTextEditor {
    width: 100%;
    height: calc(100vh - 125px);
}
.article-post .ql-toolbar.ql-snow {
    border: 1px solid rgb(136, 136, 136);  
    background: #ffffff !important;
    border-radius: 0 !important;
    padding: 15px 15px !important;
}
.article-post .ql-editor{
    padding: 20px 40px !important;
}
.article-post .ql-snow .ql-editor h1 {
    font-size: 2.1em;
    font-weight: 800;
    margin-bottom: 15px;
}
.article-post .ql-snow .ql-editor h2 {
    font-size: 1.8em;
    font-weight: 600;
    margin-bottom: 15px;
}
.article-post .ql-snow .ql-editor h3 {
    font-size: 1.5em;
    font-weight: 600;
    margin-bottom: 15px;
}
.article-post .ql-snow .ql-editor h4 {
    font-size: 1.3em;
    font-weight: 600;
    margin-bottom: 15px;
}
.article-post .ql-snow .ql-editor h5 {
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 15px;
}
.article-post .ql-snow .ql-editor h4 {
    font-size: 1.1em;
    font-weight: 600;
    margin-bottom: 15px;
}
.article-post .ql-editor p, .ql-editor ul li, .ql-editor strong, .ql-editor ol li {
    font-size: 16px !important;
    color: #32325d !important;
    line-height: 26px;
}

.article-post .subHeading[placeholder]:empty:before {
    content: attr(placeholder);
    color: #cfcfcf; 
}
.article-post [contenteditable=true]:empty:before {
    content: attr(placeholder);
    pointer-events: none;
    display: block; /* For Firefox */
  }
.article-post .ai-content:active, .article-post .ai-content:focus, .article-post .ai-content:focus-within {
    border-color: #9b9b9b8a !important;
    box-shadow: 0 0 0px 4px #6a3dfd21 !important;
}
.article-post-header .customEditorBtns{ 
    width: auto;
}
.article-post-header .editor-icon svg { 
    margin-right: 10px;
}
.article-post-header .wktooltip button {
    border: none;
    font-weight: normal;
    color:#32325d;
}
.article-post-header .wktooltip button:hover {
    color:#000;
}
.article-post-header .wktooltip button svg {
    fill: #4701ff !important;
}
.blog-post-wrap.outEditor {
    max-width: 780px;
    margin-top: 40px;
}
.blog-post-wrap.inEditor {
    max-width: 1170px;
    margin: 0 auto; 
}
.blog-post-wrap .ql-container {
    height: calc(100% - 58px);
    overflow-y: scroll;
}
.article-post-header {
    float: left;
    width: 100%;
    padding: 0;
    background: #fff;
    border-bottom: 1px solid #e7e7e7;
}
.article-post-header .editorHeader{
    text-align: right;
    display: block;
    padding:0 65px; 
    margin: 0 auto;
    float: initial;
}
.colz {
    width: 100%;
    border: 1px solid #e5e5e5;
    text-align: left;
    padding: 20px;
    margin-bottom: 20px;
    background: #f9f7ff;
    border-radius: 5px;
    font-size: 14px;
}
@media (min-width: 576px){
.modal-dialog {
    max-width: 700px;
    margin: 1.75rem auto;
}
}
.aiModal h3{
    font-weight: 600;
    font-family: system-ui;
    margin-bottom: 20px !important;
}
.aiModal h6{
    font-weight: 600;
    text-align: left;
    font-family: system-ui;
 } 
 .articleEditor{
    height: 100%;
    /* max-width: 1456px; */
    /* max-width: 1690px; */
    max-width: 100%;
    overflow: hidden; 
    align-items: stretch;
    height: calc(100vh - 72px);
 }
 .articleEditor .card {
    position: relative;
    height: 100%;
    border-radius: 0;
    box-shadow: none !important;
    border:1px solid #e6e8eb !important;
    border-top: 0px !important;
}
.articleEditor .ai-board-body { 
    overflow: hidden; 
    height: 100%; 
    border-radius: 0px !important;
}
.articleEditor .card-footer {
    padding: 0;
    background-color: #fff; 
    float: left;
    width: 100%;
    padding: 8px;
}
.editorTools {
    position: absolute;
    right: 1px;
    padding: 12px;
    background: #fff;
    top: 1px;
    z-index: 9;
}
.article-post-header .editorHeader{
    display: none;
}
.editorTools .btn-sm{
    font-size: 0.85rem;
}
.editorTools .btn-link {
    background: #f1ebff;
    font-family: system-ui;
    line-height: 21px;
}
.editorTools .btn-link:disabled {
    background: #fff;
}
.editorTools .btn-link:hover {
    background: linear-gradient(90deg,#4701ff 35%,#8223f7);
    color: #fff;
}
.editorTools .btn-link:hover svg{ 
    fill: #fff;
}
.showEditor .dropdown-menu { 
    max-width: 100% !important;
    width: 100%;
    text-align: center;
}
.ql-editor img{
    margin:15px 0;
}
.myArticles th, .myArticles td{
    border-top: 0px;
}
.myArticles th{
    padding:5px 5px 10px;
    border-bottom: 0px;
   }
.myArticles td{
 padding:8px 5px;
}
a.link {
    display: block;
    width: 100%;
    color: #1f1f1f !important;
    padding: 5px 0;
    cursor: pointer;
    float: left;
    line-height: 17px;
    font-size: 13px;
    margin-bottom: 0;
    font-weight: 500;
    font-family: system-ui;
}
a.link:hover {
    color: #6a3dfd !important;
}
a.deleteLink{
    color: #6a3dfd !important;
    cursor: pointer;
}
.footerTxt {
    font-size: 11px;
    font-family: system-ui;
    color: #a1a1a1;
}
.footerTxt span:first-child{
    margin-right: 10px;
}


.table-fixed{
    width: 100%; 
}
.table-fixed tbody{
    height:90vh;
    overflow-y:auto;
    width: 100%;
}
.table-fixed thead,.table-fixed tbody,.table-fixed tr,.table-fixed td,.table-fixed th{
    display:block;
    border: none !important;
} 
.table-fixed tbody td{
    display: inline-block;
    width: 100%;
} 

.table-fixed thead tr th{
    float:left; 
    border-bottom:1px solid #e6e8eb !important;
} 

span.aCount {
    color: #4701ff;
    font-size: 12px;
    background: #f1ebff;
    width: 20px;
    height: 18px;
    position: relative;
    top: -2px;
    display: inline-flex;
    align-content: space-around;
    justify-content: space-around;
    border-radius: 20px;
}
.sec-action .btn{
    width: 100% !important;
    font-size: 15px !important;
    margin: 0 !important;
    font-family: system-ui;
}
.sec-action .btn svg{
    fill: #fff;
    font-size: 18px;
}
.sec-action .dropdown{
    width: 100% !important;
    font-size: 16px !important;
}
.sec-action .dropdown svg{
    fill: #822add;
}
.tile{
	width: 100%; 
	border-radius:5px; 
	float:left;
  	transform-style: preserve-3d;
  	margin: 0 0;

}

.header{ 
	padding:19px 0 10px;
	text-align:left;
	color:#333; 
	font-size:19px;	
	position:relative;
}

.stats strong{
	display:block;
	color:#adb8c2;
	font-size:14px;
	font-weight:700;
} 
.stats{
	border:1px solid #ebeff2;
	background:#fff;
	overflow:auto;
	padding:12px 0;
	font-size:19px;
	color:#59687f;
	font-weight:600;
	border-radius: 0 0 5px 5px;
    margin-bottom: 10px;
}
.stats div{
	border-right:1px solid #ebeff2;
	width:50%;
	float:left;
	text-align:center
}
.stats div:last-child{
    border: none;
}
.stats div:nth-of-type(3){border:none;}
 
@media screen and (max-width: 578px){
    .articleOutlineInput .ai-content.flexbox { 
        display: flex;    
        align-items: flex-start;
        line-height: 22px;
    }
    .articleOutlineInput .selectBtn { 
        float: right;
        padding: 0;
        margin: 0 -10px 0 0; 
    } 
    .articleNav {
        display: none;
    }
    .articleEditor { 
        overflow: initial;
        height: 100%;
    } 
    .expandBtn{
        display: none;
    }
    .article-post .ql-toolbar.ql-snow { 
        padding: 55px 15px 10px !important;
    }
  }
