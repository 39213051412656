.editContent{
    overflow-x: auto;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
} 
.flexy-template .ai-board-body {
    border-radius: 0 0 6px 6px;
    background: #fff;
    padding-top: 0;
    min-height: calc(100vh - 170px);
    overflow: hidden;
    height: calc(100vh - 170px);
    overflow-y: none; 
    padding: 0;
}
.qullTextEditor{
    width: 100%;
    height: calc(98.5% - 40px);
} 
.ql-container.ql-snow, .ql-toolbar.ql-snow {
    border: 0px solid #e2e8ef !important; 
}
.ql-toolbar.ql-snow{
    border-radius: 15px 15px 0 0 !important;
    border-bottom: 0px !important;
}
.ql-container.ql-snow{
    border-radius: 0 0 15px 15px !important;
}
.ql-editor {
    /* background: #f8fbff; */
    border-radius: 0 0 15px 15px !important;
}

.ql-toolbar.ql-snow { 
    background: #fff !important;
    border-radius: 0 !important;
    padding: 8px 15px !important;
    border-bottom: 1px solid #e6e8eb !important;
    /* border-top: 1px solid #e6e8eb !important; */
}
.ql-editor{
    padding: 20px 25px !important;
}

/**Layout change**/
.flexyWrap .tool-header{
    /* display: none; */
    /* margin-top:0; */
}
.flexyWrap input#brandName {
    font-weight: 600;
}
.flexyWrap .column:last-child {
    max-width: 1500px;
    padding: 20px 66px;
    position: relative;
    width: 100%;
}
button.accordion-button {
    font-weight: 600;
    font-family: system-ui;
    color: #212529;
    padding: 10px 12px;
    font-size: 0.875rem;
}
.flexyWrap .ai-board-header.card-title.h5 {
    margin-bottom: 0;
    font-size: 1.2rem;
    padding: 15px 24px;
    font-weight: bold;
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
}
.flexyWrap .template-title{
    flex-grow: 1;
    display: none;
}
.flexyWrap .ql-editor p, .flexyWrap .ql-container{
    font-size: 15px !important;
}
.customEditorBtns {
    width: 160px;
    position: absolute;
    right: 0;
    top: 10px;
    z-index: 9;
    background: #fff;
}
/* .customEditorBtns button {
    border: 1px solid #cdcdcd;
}  */
.customEditorBtns .btn-outline-secondary:disabled svg { 
    fill: #727272 !important;
}
.customEditorBtns button svg{
    fill: #000 !important;
}
/*Tooltip*/
.wktooltip {
    position: relative;
    display: inline-block; 
  }
  
  .wktooltip .wktooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #4c4c4c;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
    font-size: 12px;
    font-weight: normal;
  }
  
  .wktooltip .wktooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #4c4c4c transparent transparent transparent;
  }
  
  .wktooltip:hover .wktooltiptext {
    visibility: visible;
  }
 .tool-panel.flexy-template .section.tool-panel .ai-board-body{
    border-radius: 6px;;
  }
  .flexyWrap section.tool-panel .ai-board .card-body {
    padding: 0 1.5rem 0;
}
  @media (max-width: 576px){
        .column:last-child {
            width: 80%;
            padding:20px !important;
            position: relative;
            flex-grow: 1;
        }
        .backToDashboard { 
            margin-top: -20px;
        }
        .blog-post-projects table.myTemplates tr td:nth-child(3),
        .blog-post-projects table.myTemplates tr th:nth-child(3) {
            display: block;
            text-align: right;
            width: 100%;
        }
        .in-text { 
             top: 0px; 
        }
        .flexyWrap .ai-board-header.card-title.h5{
            display: block;
        }
        .flexyWrap .template-title { 
            text-align: left;
            display: block;
            width: 100%;
            margin-bottom: 10px;
        }
        .customEditorBtns { 
            top: 40px;
        }
  }
  .infoTxt{
    position: relative;
    top: -13px;
    text-align: right;
    display: block;
    font-style: italic;
  }