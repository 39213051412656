.withSidebar {
    display: flex;
}
.columns.withSidebar .main-layout {
    flex-grow: 1;
}

.side-bar-nav{
    /* width: 260px;
    left: 0;
    top: 20px;
    z-index: 9; 
    overflow: hidden; 
    height: 100vh;
    float: left;
    padding: 25px 30px;
    background: #efefef; */
    width: 280px;
    left: 0;
    z-index: 9;
    overflow: hidden;
    padding: 25px 20px;
    /* border-right: 2px solid #e3e3e3; */
    position: sticky;
    top: 0;
    align-self: flex-start;
    height: 100vh;
    background: linear-gradient(1deg,#f5f9fda1 35%,#f5f9fd);
}  
.sidebar-content {
    margin-top: 70px; 
    margin-top: 0 !important;
    padding: 30px 30px;
}
.side-bar-nav .header-txt{
    display: none;
}
.side-bar-nav .card-sort-btns .btn {
    background: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 15px;
    padding: 4px 7px;
    color: #3e3e3e;
    font-size: 0.68rem; 
    margin-bottom: 10px;
    font-weight: normal;
}
.card-sort-btns span.badge.badge-light{
    font-weight: normal;
    background: #f0f0f0;
    color: #6c6c6c;
}
.side-bar-nav .card-grid-row .card p {
    color: #8e8e8e;
    line-height: 16px;
    font-size: 0.72rem !important;
}
.side-bar-nav .card-grid-row .card-body {
    padding: 10px;
    width: 75%;
    padding-left: 0;
} 
.side-bar-nav .card-grid-row .card {
    width: 100%;
    margin: 10px;
} 
.side-bar-nav .card-title.h5 {
    margin-bottom: 5px;
    font-size: 0.95rem;
    line-height: initial;
}
.side-bar-nav .card {
    border: none;
    border-radius: 0;
    box-shadow: none;
} 
.side-bar-nav .card-grid-row .card {
    width: 100%;
    margin: 0 0 10px;
    padding: 5px;
    border-radius: 5px;
}
.side-bar-nav .card-icon svg {
    margin: 0 auto;
    font-size: 20px !important;
    height: 20px;
}
.side-bar-nav .card-title.h5 {
    margin-bottom: 0;
    font-size: 0.85rem;
}
.side-bar-nav .card-grid-row .card p{
    display: none;
}
.side-bar-nav .card-icon {
    display: flex;
    vertical-align: middle;
    align-items: center;
    width: initial;
}
.side-bar-nav .card-icon .svgIcon {
    border-radius: 0;
    height: 20px;
    width: 40px;
    display: inline-block;
    padding: 0;
    margin: 0 4px 0 0;
}
.side-bar-nav .card-grid-row .card-body{
    padding: 0;
}
.sidebar-nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.sidebar-nav a.sidebar-link, .sidebar-nav .sidebar-link{
    cursor: pointer;
    color: #000000;
    display: flex;
    align-items: baseline;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: auto;
    transition: all 0.3s ease;
    font-size: 14px;
    position: relative;
    border-radius: 0;
    padding: 5px 0;
    display: flex;
}
a.sidebar-link svg {
    max-width: 16px;
    margin-right: 12px;
    height: 15px;
}
span.count {
    background: #e9eff8;
    width: 25px;
    text-align: center;
    font-weight: normal;
    font-size: 11px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    margin-left: 15px;
    color:gray;
}
a.sidebar-link.active,
.sidebar-nav .sidebar-link.active { 
    color: #4701ff !important;
    font-weight: bold;
    /* background: #4701ff14; */
  }
.sidebar-nav a.sidebar-link.active svg,
.sidebar-nav a.sidebar-link.active svg path {
    fill: #4701ff; 
}
.sidebar-nav .subHead { 
    display: inline-block;
    position: relative;
    font-size: 12px;
    color: #7f7f7f;
    margin-top: 0px;
    text-transform: uppercase;
    font-weight: normal;
}
.sidebar-nav a.sidebar-link svg {
    fill: #4f5966;
}
svg.folder {
    max-width: 19px;
}
.usageSec {
    position: absolute;
    bottom: 40px; 
    left: 20px;
    right: 20px;
}
.sidebar-nav a.sidebar-link .newFeature {
    position: relative;
    font-size: 9px;
    background: #4701ff;
    color: #fff;
    padding: 1px 5px 2px;
    border-radius: 5px;
    position: relative;
    top: 0px;
    left: 5px;
}
.linkTitle{
    display: flex;
    flex-grow: 1;
    text-transform: capitalize;
}
.arrow-icon {
    display: inline-block;
    border: solid;
    border-width: 0 1px 1px 0;
    padding: 0.14rem;
    transform-origin: center;
    transition: transform 0.3s;
    color: gray;
  }
  
  .sidebar-nav ul ul {
    background: #fdfeff;
    border-radius: 0;
    padding: 10px 10px 20px;
    border-left: 3px solid #ebedf0;
}
.sidebar-nav ul ul li svg{
    display: none;
}
.sidebar-nav ul ul li a.sidebar-link{ 
    color: rgb(56 56 56); 
    height: auto; 
    /* padding-left: 16px; */
    font-size: 14px;
    color: #000;
}
  .arrow-icon.up {
    transform: rotate(-135deg);
  }
  
  .arrow-icon.down {
    transform: rotate(45deg);
  }
  
  .sub-menu {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease-out;
  }
  
  .sub-menu.open {
    max-height: 500px; /* Adjust this value to accommodate the contents of your sub-menu */
  }
  .nested-list {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
  }
  
  .nested-list-expanded {
    max-height: 500px; /* Adjust as needed, should be large enough to show all items */
    transition: max-height 0.5s ease-in;
  }

  .new-tools span.count{
    margin-left: 0;
    margin-right:16px !important; 
  }
  .emoji-icon{
    margin-right:10px;
  }
  .emoji-icon.favrIcon {
    margin-right: 10px;
    opacity: 0.7;
}
a.fav-link {
    cursor: pointer;
    color: #000000 !important;
    display: flex;
    align-items: baseline;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: auto;
    transition: all 0.3s ease;
    font-size: 14px;
    position: relative;
    border-radius: 0;
    padding: 2px 0;
}
.myAccountSideBar{display: none;}
.hamburger-menu{display: none; 
    margin-top: 15px;
    font-weight: 800;}
    
/* Make the scrollbar transparent by default */
.fixed-sidebar::-webkit-scrollbar {
    width: 6px; /* Adjust the width as needed, but keep it consistent */
    background: transparent; /* Ensures scrollbar takes space but is invisible */
}

/* Optional: make the scrollbar thumb transparent as well */
.fixed-sidebar::-webkit-scrollbar-thumb {
    background: transparent;
}

/* On hover, change the scrollbar and thumb to be visible */
.fixed-sidebar:hover::-webkit-scrollbar {
    background: transparent; /* Background of the scrollbar when visible */
}

.fixed-sidebar:hover::-webkit-scrollbar-thumb {
    background: #cecece; /* Color of the scrollbar thumb when visible */
    border-radius: 4px; /* Optional: adjust for roundness */
}

/* Optional: Style the scrollbar track on hover, if needed */
.fixed-sidebar:hover::-webkit-scrollbar-track {
    background: transparent; /* Color of the scrollbar track when visible */
}
.dahsboard-icon{
    height: 16px;
}
li.botIcon svg {
    height: 16px;
    position: relative;
    bottom: -3px;
}
@media only screen and (max-width: 768px){
    .caption{
        display: none;
    }
    .tool-header h2 {
        font-size: 22px;
        line-height: 28px;
    }
    .ql-editor {
        padding: 20px 0 !important;
    }
    .flexyWrap section.tool-panel .ai-board .card-body {
        padding: 0 10px;
    }
    section.tool-panel .ai-board-body { 
        height: auto !important;
        min-height: 395px !important;
    }
    .hamburger-menu{display: block;transform: rotate(-90deg);}
    .hamburger-menu:hover{color: #4701ff; cursor: pointer;}
    .navbar-toggler{
        display: none;
    }
    .myAccountSideBar{display: block;}
    .myprofile-link.navbar-nav {
        display: none;
    }
    .center-nav.navbar-nav {
        display: none;
    }
    .fixed-sidebar.col-md-3 {
        flex: 0 0 0%;
        max-width: 0%;
    }
    .sidebar-content { 
        padding: 30px 30px;
    }
    .sidebar {
        transition: transform 0.3s ease-in-out;
        transform: translateX(-100%); 
        flex: 0 0 0%;
        max-width: 0%;
        background-color: #fff;
      }
      
      .sidebar.visible {
        transform: translateX(0);
        flex: 0 0 100%;
        max-width: 100%;
        height: 100vh;
        overflow: auto;
      }
}

