.table-bordered th, .table-bordered td { 
    font-size: .95rem;
    line-height: 1em;
    letter-spacing: .01em;
}
.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: rgb(255 255 255 / 5%);
    color: var(--bs-table-striped-color);
}
.trendBar{
    background-color: #cbcbcb;
    border-radius: 10px; 
}
.trendBar .progress-bar {
    background: #865feb;
}


.search-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;
  }
  
  .search-row {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .search-input {
    margin-right: 10px;
    min-width: 350px;
  }
  
  .keyword-table {
    margin-top: 10px;
    margin-bottom: 0;
    border: none;
    font-size: 15px;
  }
  .keyword-table tr,  .keyword-table td,  .keyword-table th{ 
    border: none;
    padding: 8px 0;
  }
  .keyword-trend {
    margin-top: 30px;
  }
  .keyword-table th{ 
    line-height: 30px; 
    font-weight: normal;
  }
  .bar-chart {
    width: 100%;
    height: 300px; 
  }
  .search-row .col {
    padding: 0 5px;
}
  .select-input {
    min-width: 220px;
}
.keywordSearchForm .form-control {
    font-size: 16px;
}
.css-2xov4a-control{
    height: 45px !important;
}
.keywordSearchForm{
    /* background: #fff; */
    padding: 21px 32px;
    border-radius: 15px;
    border-radius: 6px;
    /* box-shadow: 0px 1px 3px rgb(0 0 0 / 25%); */
    border: none !important;
    /* border: 1px solid #33333330 !important; */
}
.keywordSearchForm .form-control { 
    background: transparent;
    border-color: #cccccc;
}
.keywordSearchForm .form-control:focus {
    background: transparent;
}
 .keywordResearch{
    padding: 35px 0 20px;
 } 
 .keywordResearch h1{
    margin:0 0 20px;
    font-size: 28px;
    font-weight: bolder;
 } 
.keywordResearch h2,
.keywordResearch h3,
.keywordResearch h4,
.keywordResearch h5,
.keywordResearch h6{
    margin: 0 0 20px;
    padding: 0; 
    font-weight: 600;
}
.keywordResearch h2{
    font-size: 20px;
    margin-bottom: 30px;
}
.keywordResearch h3 {
    font-size: 15px;
    margin: 0;
}
  .keywordOverview .card{
    min-height: 120px;
    padding: 20px 0;
  }

  .keywordOverview .card h4{
    font-weight: 400; 
    font-size: 1.728rem;
    margin:0; 
  }
  .keywordOverview .card h6{
    font-weight: 600; 
    margin-top: 10px;
    margin-bottom: 4px;
    font-size: 0.9333333333rem;
  } 
.overviewCard.row .card {
    border: 0;
    box-shadow: none;
    background: none;
    border: none;
}
.borderRight{
    border-right:1px solid #dbdbdb ;
}
.borderBottom{
    border-bottom:1px solid #dbdbdb ;
}
 .rowSection{
    padding: 20px 30px;
 }
 
/* .overviewCard.row .row {
    column-gap: 1.2em;
} */
 
.kIcon{
    position: relative;
}
.kIcon svg{
    position: absolute;
    fill:#b03dfd;
    right: 10px;
    bottom: 7px;
    width: 30px;
} 
.volIcon svg{
    fill: #1dbf0a; 
}
.cpcIcon svg{
    fill: #fb75cf; 
}
.compIcon svg{
    fill: #1f90d7; 
}
.trendIcon svg{
    fill: #7571ff; 
}
.keyWord{
    color: #6a3dfd;
    padding: 5px 10px;
    background: #e8e1ff;
    border-radius: 5px;
    font-size: 16px;
} 

.secondRow ul {
    list-style: none;
    text-align: left;
    margin: 0;
    padding: 0;
    margin: 15px 0;
}

.secondRow ul li {
    padding: 0;
    margin: 0 0 10px 0;
}

.secondRow ul li button {
    margin: 0;
    padding: 0;
    text-align: left; 
}
.secondRow ul li button {
    font-weight: normal;
}
.kd .low{
 color:#40af33;
}
.kd .medium{
    color:#fb8e40; 
}
.kd .heigh{
    color:red;  
}
.keyOverview .row {
    margin: 0;
}
.keyOverview {
    width: 100%;
    border-radius: 6px;
    /* box-shadow: 0px 1px 3px rgb(0 0 0 / 25%); */
    border: none;
    overflow: hidden; 
    border: 1px solid #dbdbdb;
}
.keywordIdea .card {
    padding: 30px !important;
}
.keywordIdea .btn:not(:disabled):not(.disabled):active:focus, .keywordIdea .btn:not(:disabled):not(.disabled).active:focus{
    box-shadow:none;
    color: #6a3dfd;
}
.abtn-link { 
    color: #6a3dfd !important;
    cursor: pointer;
    font-size: 14px;
}
.keywordResearch .header-txt h2 {
    font-size: 2rem;
    font-weight: 800;
    line-height: 2.5rem;
    margin: 0 0 10px;
    text-transform: capitalize; 
}
.keywordResearch th, .keywordResearch td{
    padding: 0;
    margin: 0;
    padding-bottom: 10px;
}
.keywordResearch th{
    font-size: 14px;
    padding-bottom: 15px;
}
.keywordResearch ul{
    padding: 0;
    margin: 0;
    padding-bottom: 10px;
    list-style: none;
}
.keywordResearch ul li{ 
    padding-top: 10px;
    padding-bottom: 0px;
    list-style: none;
    margin: 0;
}
.keywordResearch h3{ 
    padding-bottom: 10px; 
    font-size: 18px;
}
.seo-selected strong {
    font-size: 14px;
    margin-bottom: 5px;
    display: inline-block;
} 
.seo-selected p {
    margin-bottom: 10px;
}
@media (max-width: 570px) {
    .keyOverview {  
        margin-bottom: 50px;
    }
    .search-input {
        margin-right: 0;
        min-width: 252px !important;
    }
    .search-row { 
        padding: 40px 40px 0;
    }
    .keywordSearchForm .col{
        width: 100% !important;
        margin-bottom: 20px;
    } 
}

span.trend-data.text-red-500 {
    color: red;
}
span.high {
    color: red;
}

span.low {
    color: #1bbd00;
}

span.medium {
    color: rgb(247, 146, 31);
}
table.related-table td {
    font-size: 15px !important;
    text-transform: lowercase;
}
table.related-table td label {
    color: #4701ff !important;
    font-size: 15px !important;
}
span.trend-data {
    font-size: 14px;
}
table.related-table tbody tr:hover {
    background: #0000000f;
    vertical-align: super;
}
table.related-table td{
  padding: 5px 0;
}
table.related-table td:first-child,
table.related-table th:first-child{
    padding-left: 10px;
  }
.css-1nmdiq5-menu{
    color: rgb(55, 55, 55);
  }
  .keywordResearch thead th > div {
    display: flex;
}
.keywordResearch thead th > div svg {
   width: 18px;
   margin-left:3px;
}