.profile .card{
    height: auto;
}
.progress {
    height: auto;
}
.progress-bar {
    width: 100%;
    height: 1em;
}
.page-layout{
    max-width: 960px;    
    margin: 0 auto;
    padding-top: 60px;
}
.page-layout .header-txt h2{
    text-align: center !important;
}
.subHead{
    margin-bottom: 10px;
    display: inline-block; 
}
.subHead h6{ 
    font-weight: 600;
    color:#252629;
    margin-bottom: 0;
}
.profile .card .card-body {
    flex: 1 1 auto;
    padding: 1.5rem 3rem;
}
.billing .row {
margin-left: -5px;
margin-right: -5px;
}
.billing .col{
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    border: 2px solid #edf1fb;
    padding: 15px;
    border-left: 0;
    padding-bottom:0;
}
.billing .col:first-child {    
    border-left: 2px solid #edf1fb;
}
.loaderStyle .loader {
    margin: 0;
    background-size: 50%;
    object-fit: scale-down;
    transform: scale(0.6);
    position: relative;
    top: -10px;
    right: 30px;
}
.profileImage{
    height: 112px; background-repeat: no-repeat; border-radius: 74px !important; background-size: cover;
}
.cancelSubscriptionModal .modal-dialog{
    max-width: 560px !important;
}
.dpNone{
    display: none;
}