.my-content-table *{
    font-size: 15px !important;
}
td.text-truncate .content-data * {
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    margin: auto;
    display: inline-table;
}
.my-content-table button.btn svg {
    font-size: 14px;
    margin: -2px 2px 0 0;
    fill: none;
}