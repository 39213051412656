.header-nav.navbar{

} 
.logo{ 
    background-position: top left;
    background-repeat: no-repeat;
    float: left;
    background-size: contain;
    margin: 0px 20px 0 0px;
    max-width: 140px;
}
.nav-link{
    color: #333;
    font-weight: 500;
}
.nav-link.active{
    color: rgb(240 168 14); 
}
.navbar-light .navbar-nav .nav-link {
    color: #333;
    font-weight: 600;
}
.navbar-light .navbar-nav .nav-link svg{
    width: 20px;
    height: 20px;
    color:#6a3dfd;
    margin-right: 10px;
}
span.profile-img svg {
    margin: 0 auto !important;
    padding: 0;
    fill: #fff;
    width: 16px !important;
}
.dropdown-toggle::after {
    display: none;
  }
.text-decoration-none{
  text-decoration: none!important;
}
/* Font Awesome Icons have variable width. Added fixed width to fix that.*/
.icon-width { width: 2rem;}
.navbar .dropdown-menu .dropdown-item {
    padding: 0.5rem 1rem;
    font-size: 14px;
}
.navbar .navbar-nav .dropdown-menu:before{
    left:inherit;
    right:35px;
}

/* .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
    flex-grow: 1;
    justify-content: center !important;
} */
.navbar-expand-lg .navbar-nav:last-child{
    float: right;
    position: absolute;
    right: 3rem;
}
.header-nav .center-nav.navbar-nav {
    margin-left: -200px;
}
.header-nav .navbar-light .navbar-nav .nav-link.active {
    color: rgb(94 114 228);
}
.center-nav svg {
    /* fill: #6a3dfd; */
    font-size: 20px;
}
svg.clrIcon {
    fill: none !important;
    font-size: 20px;
}
.dropdown-item.active, .dropdown-item:active {
    text-decoration: none;
    border-color: #e5e5f8;
    background: #5c2adf14;
    color: #4701ff;
    font-weight: 600;
}
.dropdown-item:hover, .dropdown-item:focus {
    color: #4701ff;
    text-decoration: none;
    background-color: #f2eefc;
}
.header-nav .dropdown-menu .dropdown-item {
    display: flex;
    line-height: 24px;
}
.header-nav .dropdown-menu .dropdown-item > svg {
    width: 16px;
    margin: 0 14px 0 0;
    padding: 0;
    fill: #747474;
    stroke: #747474;
}
.profile-img{
    position: relative;
    width: 36px;
    height: 36px;
    top: 8px; 
    margin-right: -8px;
}
.profile-img img{
    border-radius: 50px; 
}
.templateList .dropdown-item {
    padding: 10px 30px !important;
    font-size: 15px !important;
}
.dropdown-item.active, .dropdown-item:active{
   font-weight: inherit;
}  
.navbar .dropdown-menu .dropdown-item {
    padding: 10px 24px;
    font-size: 14px;
}
.dropdown-menu.show.dropdown-menu-end, .basic-nav-dropdown {
    padding-bottom: 0px;
    border-radius: 0 0 10px 10px;
}
a.showAllTemplates {
    background: #4701ff !important;
    color: #fff !important;
    margin: 0;
    float: left;
    width: 100% !important;
    border-radius: 0 0 10px 10px;
    padding: 0 !important;
    text-align: center !important;
}
a.showAllTemplates:hover{
    text-indent: 5px;
}

.navbar-expand-lg .navbar-nav:last-child {
    float: right;
    position: absolute;
    right: 1rem;
}
.profileImgWrap {
    float: left;
    position: relative;
    left: -5px;
    margin-right: 10px !important;
    display: flex;
    align-items: center;
}
.profile-img {
    margin: 0 10px 0 0;
    top: 0;
    border-radius: 50px;
    background: #673ab7;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.myprofile-link .dropdown-toggle{
    padding: 0 !important;
} 
.profileImgWrap .linkName {
    height: 37px;
    display: flex;
    align-items: center; 
}
.myprofile-link .dropdown-menu {
    top: 45px;
}
.wordsUsed { 
    background: #e9e8ff;
    padding: 0px 7px 0 0;
    border-radius: 20px;
    color: #4701ff;
    font-size: 11px;
    font-family: system-ui;
    font-weight: 700;
    letter-spacing: 0.4px;
    height: 20px;
    margin: 17px 40px 0 0;
}
.wordsUsed p {
    margin: 0 !important;
    display: initial;
    font-size: 10px !important;
    line-height: 18px;
    font-weight: bold !important;
    padding: 0 !important;
}
i.wIcon {
    height: 0;
    padding: 3px 4px;
    font-size: 9px;
    text-transform: uppercase;
    background: #4701ff;
    border-radius: 50%;
    margin-right: 5px;
    color: #e9e8ff;
    font-style: normal;
}
.header-nav.navbar {
    padding: 0;
    min-height: 50px;
}
.header-nav .nav-link {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem; 
}
.header-nav a.nav-link.active { 
    color: #4701ff !important;
}   

 
.dashboardLink svg{
    width: 18px !important;
}
.dashboardLink svg { 
    fill: #4701ff;
} 
a.head-login-btn {
    height: auto;
    padding: 5px 25px 8px;
    border-radius: 4px;
    line-height: initial;
    margin: 0 30px;
} 
.main-header a.logo {
    margin: 13px;
}
.head-center{
    display: flex;
    padding: 7px;
    justify-content: center;
    align-items: center;
}
.head-right{
    display: flex;    
    justify-content: flex-end;
}

@media only screen and (max-width: 768px) {
    .dview{
        display: none;
    }
    .header-nav.navbar {
        display: none;
    }
    .wordsUsed {  
        margin: 17px 25px 0 0;
    }
    .main-header .col:nth-child(2){
        display: none;
        }
    .navbar-toggler:focus {
        box-shadow: none;
    }
    .dropdown-item:hover, .dropdown-item:focus, .dropdown-item.active, .dropdown-item:active { 
        background: transparent;
    }
    .navbar-light .navbar-brand {
        color: #32325d;
        z-index: 9999;
    }
    .header-nav .center-nav.navbar-nav {
        margin-left: 0;
    }
    div#basic-navbar-nav{
        height: 100vh !important; 
        border-radius: 0;
        max-width: 86%;
        padding-top: 80px;
        display: flex !important;
        flex-direction: column-reverse; 
        justify-content: flex-end !important;
    }
    /* .navbar-collapse { 
        position: absolute;
        left: -300px !important;
        transition: 1s;
    }
    .navbar-collapse.show {
        left: 0px !important;
        transition: 1s;
    }
    
    .navbar-expand-lg .navbar-collapse > div {
        width: 100%;
        overflow: hidden;
        overflow-y: scroll; 
    }
    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
        flex-grow: 1;
        justify-content: inherit !important;
        align-items: baseline;
        margin: 0;
        width: 99.90%;
        padding-top: 10px;
    } */
    .navbar-expand-lg .navbar-nav:last-child { 
        right: 0;
        float: initial;
        position: initial;
    }
    .navbar-expand-lg .navbar-collapse .dropdown-menu {
        margin: 0 0 0 10px !important;
        padding: 0;
    }
    .navbar-nav > a, .navbar-nav > div {
        margin-bottom: 10px;
    }
    .navbar .dropdown-menu .dropdown-item {
        padding: 10px 0px 10px 24px !important;
        font-size: 14px;
    }
    .templateList .dropdown-item {
        padding: 10px 0px 10px 24px !important;
    }
    a.showAllTemplates{
        border-radius: 10px;
    }
    .profileImgWrap{
        float: left;
        position: relative;
        left: -5px;
        margin-right: 10px !important;
        display: flex;
        align-items: center;
    }
    .linkName{
        float: left;
    }
    .profile-img {
        display: inline-block;
        margin: 0 10px 0 0;
        top: 0;
        border-radius: 50px;
        background: #673ab7;
        width: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .profile-img svg {
        fill: #fff;
    }
    button.navbar-toggler {
        border: none;
        background: transparent;
    }
    .nav-link svg {
        margin-right: 10px !important;
    }
  }
  @media only screen and (max-width: 768px){
    .wordsUsed { 
        right: 10px;
        top: 45px; 
    }
  } 
  .search-bar-container {
    margin-bottom: 20px;
  }
  
  .search-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  .search-bar .form-control {
    border: none;
    background: transparent;
    border-bottom: 1px solid #e1e1e1;
}
  .search-bar {
    position: relative;
    min-width: 330px;
}
.search-bar .dropdown-menu.show {
    display: block;
    padding: 0;
    max-height: 350px;
    overflow: hidden;
    overflow-y: scroll;
    min-width: 333px;
}
.search-bar .navbar .dropdown-menu .dropdown-item {
    padding: 10px 12px;
    font-size: 14px;
}
  .search-bar input {
    padding: 0 12px;
    height: 34px;
    background: white;
}
.search-bar .input-group .form-control:focus{
    box-shadow: none !important;
}
.search-bar .input-group-text {
    transition: all 0.15s ease;
    height: 34px;
    border: none;
    background: transparent; 
}
.search-bar .input-group-text span {
    opacity: 0.6;
}
.search-bar .navbar-expand-lg .navbar-nav .dropdown-menu {
    width: auto !important;
}
a.dashboardLink {
    padding: 6px 26px 0 0;
    font-size: 14px;
}
div#basic-navbar-nav {
    justify-content: center !important;
}
