.loginForm {
  /* box-shadow: none; */
  /* border: 1px solid #cad1d7; */
  font-size: 14px;
  text-align: center;
  max-width: 430px;
  min-width: 400px;
  border: none !important;
}
.inlineLogin .loginForm{
  max-width: 430px;
    min-width: 440px;
}
.loginForm h2{ 
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 30px;
}
.googleSignin{
  background: transparent;
    color: #646464;
    border: 1px solid #cad1d7;
    border-radius: 0.25rem;
    padding: 2px;  
    height: auto; 
    box-shadow: none;
}
.googleSignin:hover{
  border-color: #d1d2d7;
  background-color: #f8faff;
  color: #646464;
}
.googleSignin svg {
  width: 30px;
}
.loginForm hr{
  border:none;
  position: relative;
}
.loginForm hr:after{
  content: 'Or';
  position: absolute;
  background: #fff;
  padding: 5px 8px;
  left: 46%;
  top: -15px;
  color: #000;
  font-size: 14px;
  font-weight: bold;
}
.termsofService {
  font-size: 13px;
  margin: 20px 0 0;
  color: #8d8d8d;
}


.inlineLogin{
  display: flex;
}
.loginState .modal-body {
  padding: 0;
  margin: 0;  
}
.loginState .modal-dialog {
  max-width: 850px;
} 
.inlineLogin .imageCard {
  flex-grow: 1;
  background-image: url(https://wordkraft.ai/wp-content/uploads/2023/03/ai-content-writing.jpg);
  border-radius: 10px 0 0 10px;

  align-items: center;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  border: none !important;
}
.inlineLogin .loginForm {
  padding: 22px;
}
.inlineLogin button.btn-primary { 
  padding: 5px 10px;
  min-height: 40px;
  font-size: 16px !important;
}
.modal-backdrop.show {
  opacity: 0.66;
}
.imagekraft-benifts {
  color: #fff;
  text-align: left;
}
.imagekraft-benifts strong{
  font-weight: 600;
  font-size: 28px;
}
.imagekraft-benifts ul {
  margin: 0;
  padding: 0;
  margin-left: 17px;
}
.imagekraft-benifts ul li{
  font-size: 17px;
  line-height: 2; 
  list-style: none;
}
.imagekraft-benifts ul li .ico{
  margin-right: 10px;
}
.imagekraft-benifts {
  color: #fff;
  text-align: left;
  position: relative;
  z-index: 5;
}
.inlineLogin .imageCard:after {
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(44deg,#290b9d,rgb(37 9 121 / 85%) 35%,#a200ff);
  z-index: 1;
  border-radius: 10px 0 0 10px;
}
.linkFont{
  font-size:13px ;
}
.loginForm button.btn-primary.googleSignin {
  background: #f2f1f1;
  box-shadow: 0 1px 4px #33333387;
  color: #0c0c0c;
  border: none;
}
@media (max-width: 573px){ 
  .inlineLogin {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: nowrap;
    border-radius: 0px;
  }  
  .inlineLogin .loginForm { 
    margin: 0px;
}
.inlineLogin .imageCard {
  padding: 50px 15px !important;
  margin: 0px;
}
  .inlineLogin .imageCard:after {
    border-radius: 0px;
  }
  .loginState.modal {
    z-index: 999999;
    margin-top: 5%;
  }
}
