.chat-head-content {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
}

/* Dropdown button styling */
.agent-selector-toggle {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px !important;
  padding: 0.5rem 1rem; 
  font-size: 13px;
  box-shadow: none !important;
}
.agent-selector-toggle.btn:not(:last-child) {
  margin-right: 0;
}
:root[data-theme='dark'] .agent-selector-toggle{  color: #fff;}
:root[data-theme='dark'] .agent-selector-toggle:hover{  color: #cbd5e1 !important;}
/* Dropdown menu styling */
.agent-selector-menu {
  width: 400px;
  padding: 0;
  border: 1px solid #e2e8f0;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

/* Search container */
.agent-selector .search-container { 
  margin: 0;
}

.search-container .input-group {
  background: white;
}

.search-container .form-control {
  font-size: 0.875rem;
}

.search-container .input-group-text {
  border-left: none;
}

/* Section headers */
.section-header { 
  background: #79797914;
}

.section-header .text-muted {
  font-size: 0.75rem;
  color: #64748b !important;
}

/* Agent items */
.agent-item {
  padding: 0.75rem 1rem !important; 
}

.agent-item:hover {
  background-color: #f8fafc;
}

.agent-icon {
  color: #64748b;
}
.agent-icon svg {
  width: 34px !important;
  height: 20px;
}
.agent-item .agent-name {
  font-size: 0.875rem;
  font-weight: 500; 
  margin-bottom: 0.25rem;
  padding: 0;
}

.agent-description {
  font-size: 0.75rem; 
  line-height: 1.5;
  text-wrap: initial;
}

/* Empty state messages */
.text-muted.small {
  font-size: 0.75rem;
  color: #64748b !important;
  padding: 0.75rem 1rem;
}

/* Agents container */
.agent-selector-menu .agents-container {
  max-height: 400px;
  overflow-y: auto;
}

/* Custom scrollbar */
.agents-container::-webkit-scrollbar {
  width: 6px;
}

.agents-container::-webkit-scrollbar-track {
  background: #f1f5f9;
}

.agents-container::-webkit-scrollbar-thumb {
  background: #cbd5e1;
  border-radius: 3px;
}