/* .agents-container {
  flex: 1;
  transition: margin-left 0.3s ease;
  margin-left: 280px;
  overflow-y: auto;
}
.agent-container{
  height: calc(100vh - 150px);
  overflow: hidden;
  overflow-y: auto;
  padding:5px 0;
  padding-right: 30px;
}
.agents-container.sidebar-collapsed {
  margin-left: 80px;
}

.agents-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
} */
.create-agent-form {
  max-width: 720px !important;
  margin: 0 auto;
  }
  .create-agent-form .model-selector button {
    padding: 0 !important;
    margin: 0 0 0 15px !important;
  }
.agents-page .category-filter {
  border-radius: 6px;
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #e9e9e9;
}

.agents-page .create-agent-button {
  font-size: 14px;
  padding: 8px 16px;
}

.agents-page .agent-card {
  height: 100%;
  transition: all 0.2s ease;
}

.agents-page .agent-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}
.agent-dropdown .btn svg {
  display: none;
}
.agent-load{
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.offcanvas-end{
  width: 600px;
}
.offcanvas-body{ 
  padding: 0.5rem 2rem 0.5rem; 
}
.offcanvas-header { 
  padding: 1.5rem 2rem 0.5rem; 
}
.offcanvas-title h4,
.offcanvas-title h5 {
  font-size: 18px;
  font-weight: bold;
  padding: 2px 0;
}