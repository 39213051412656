.agent-card {
    border: 1px solid #eee;
    border-radius: 12px;
    background: white;
    transition: all 0.2s ease;
    overflow: hidden;
  }
  
  .agent-card:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  }
  
  .agent-content {
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .agent-icon {
    width: 48px;
    height: 48px;
    margin-bottom: 16px;
    color: #6366f1;
  }
  
  .agent-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #1a1a1a;
    margin-bottom: 8px;
  }
  
  .agent-description {
    color: #666;
    font-size: 0.875rem;
    line-height: 1.5;
    margin-bottom: 16px;
  }
  
  .agent-metadata {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 24px;
  }
  
  .category-badge {
    background: #6366f11c;
    color: #7a8088;
    padding: 4px 12px;
    border-radius: 16px;
    font-size: 0.875rem;
  }
  
  .creator-text {
    color: #6b7280;
    font-size: 0.875rem;
  }
  
  .start-chat-button {
    width: 100%;
    background:#6366f1; 
    color: white; 
    border: none;
    border-radius: 5px;
    padding: 6px 12px;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s ease; 
  }
  
  .start-chat-button:hover {
    background: #4042c6;
    color: white;
  }
  .action-button svg{ 
    color: #6366f1; 
    background: transparent;
  }
  
  .action-buttons {
    display: grid;
    grid-template-columns: repeat(4, 1fr);    
  }
  
  .agent-content .action-buttons{
    opacity: 0;
  } 
  .agent-content:hover .action-buttons{
    display: grid;
    opacity: 1;
  }
  .action-button {
    padding: 8px;
    border: none;
    background: transparent;
    cursor: pointer;
    color: #6b7280;
    border-radius: 4px;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .action-button:hover {
    background-color: #f3f4f6;
  }
  
  .action-button.favorite {
    color: #fbbf24;
  }
  
  .action-button.favorite:hover {
    color: #f59e0b;
  }
  
  .action-button.delete-button {
    color: #ef4444;
  }
  
  .action-button.delete-button:hover {
    color: #dc2626;
  }
  .agent-icon svg {
    width: 60px;
    color: #6366f1;
    height: 60px;
}
.agent-card {
    border: 1px solid #eee;
    border-radius: 12px;
    background: white;
    transition: all 0.2s ease;
    overflow: hidden;
  }
  
  /* Previous styles remain the same... */
  
  .action-button.favorite svg{
    color: #fbbf24;
    position: relative;
  }
  
  .action-button.favorite:hover svg{
    color: #f59e0b;
  }

  .favorite-spinner {
    position: absolute;
    width: 16px;
    height: 16px;
    border: 2px solid rgba(251, 191, 36, 0.3);
    border-radius: 50%;
    border-top-color: #fbbf24;
    animation: spin 0.8s linear infinite;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  .agent-card .agent-icon svg {
    width: 60px;
    height: 60px;
}
.agent-selector-menu .agent-icon{
    padding: 0 !important;
    margin: 0 !important;
}
.agent-selector-menu .agent-description{
    padding: 0 !important;
    margin: 0 !important;
}
.agent-selector-menu .agent-icon svg {
    width: 30px !important;
    padding: 0 !important;
    margin: 0 !important;
    height: 30px !important;
}
.agent-content .action-buttons { 
    width: 100%;
}
.customize-btns{
    display: flex;
    justify-content: space-between;
}
/***/
.agents-page .category-filter {
  border-radius: 6px;
  padding: 8px 12px;
  font-size: 14px;
  width: auto;
  min-width: 200px;
  background-color: var(--bg-primary);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  cursor: pointer;
  transition: all 0.2s ease;
}

.agents-page .category-filter:hover {
  border-color: var(--text-muted);
}

.agents-page .category-filter:focus {
  border-color: var(--text-muted);
  box-shadow: 0 0 0 2px var(--shadow-color);
  outline: none;
}
