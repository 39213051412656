@media (min-width: 1200px){
    .container {
        max-width: 1170px;
    }
}
.card-grid-row{
    display: flex; 
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
}
.card-grid-row .card{
    width: 31%;
    margin: 10px;
}
.card-grid-row .card:hover{
    box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
    transform: translateY(-1px);
}
.card-grid-row .card-body { 
    padding: 20px;
    width: 75%;
    padding-left: 0;
}
.card-grid-row .card p {
    color: #979797;
    line-height: 19px;
    font-size: 0.82rem !important;
}
.card-wrap{
    display: flex;
}
.card-icon{
    display: flex;
    vertical-align: middle; 
    align-items: center;
    width: 25%;
}
.card-icon i{
    margin:0 auto;
}  
.card-icon svg{
    margin: 0 auto;
    font-size: 40px;
}
.card-title.h5 {
    margin-bottom: 0.25rem;
    font-size: 1rem;
} 
.header-txt h5{
    font-size: 1.20rem;
    font-weight: 600;
    margin: 0 0 5px;
    text-align: left;
}
.header-txt p{
    font-size: 0.75rem;
    font-weight: 400;
}
.user-name{
    text-transform: capitalize;
    font-weight: 400;
}

.card[data-category="blog"] {
    border-left: solid #2196f3 4px;
}

.card[data-category="website"] {
    border-left: solid #7cb342 4px;
}

.card[data-category="socialMedia"] {
    border-left: solid #f44336 4px;
}

.card[data-category="marketing"] {
    border-left: solid #f3b915 4px;
}
.card[data-category="more"] {
    border-left: solid #11cdef 4px;
}
.card-grid-row .card{    
    border: none;
}
.card-title.h5 {
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 600;
    font-family: system-ui;
}
.secHeading h3 {
    font-size: 1.1rem;
    font-weight: 600;
    margin: 0;
}
.secHeading {
    display: inline-block;
    width: 100%;
    padding: 10px;
}

.card-icon .svgIcon svg { 
    margin: 0 auto;
    display: block;
}
.card-icon .svgIcon {
    /* background: #f1f5fb; */
    margin: 10px auto;
    padding: 10px;
    border-radius: 50px;
    height: 65px;
    width: 65px;
    display: flex;
}
.card[data-category="socialMedia"] .card-icon .svgIcon svg,
.card[data-category="ads"] .card-icon .svgIcon svg,
.card[data-category="ecommerce"] .card-icon .svgIcon svg{
    width: 100%;
}
.newFeature {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 10px;
    background: #4701ff;
    color: #fff;
    padding: 3px 8px 2px;
    border-radius: 5px;
}
.dashboard-content h3{
    font-size: 1.3em !important;
    margin-bottom: 5px;
}
.dashboard-content .secHeading p{    
    font-size: 0.95rem !important;
    margin-bottom: 0;
}
a.quickLink {
    margin: 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
}
.conWrap {
    width: 100%;
    display: flex;
    column-gap: 5px;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 50px;
}
.card-grid-row .new-tools .card p,
.new-tools .card ul li{
    color: #333;
    font-size: 14px !important;
    margin-bottom: 0;
    line-height: 25px;
}
.card-grid-row .new-tools .card{
    padding: 25px;
}
.dashboard-content .secHeading {
    margin-bottom: 15px;
}
.dashCaption {
    color: #5e666e;
    font-size: 20px;
    font-weight: 600;
}

.tools-cards .card-title.h5 {
    margin-bottom: 10px;
    font-size: 1.2rem;
    font-weight: 600;
    font-family: system-ui;
    line-height: 24px;
}
.tools-cards.card-grid-row .card p {
    color: #7f7f7f;
    line-height: 20px;
    font-size: 0.84rem !important;
    display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
}
.tools-cards .card-icon {
    display: flex;
    vertical-align: top;
    align-items: start;
    width: 25%;
}
.tools-cards .card-icon .emoji{
    font-size: 35px; 
    margin: 10px auto;
    padding: 10px;
    border-radius: 50px;
    height: 65px;
    width: 65px;
    display: flex;
}

.category-dropdown {
    display: none;
  } 
  .main-layout.templates { 
    max-width: 1350px;
}
  @media (max-width: 768px) {
    .card-sort-btns {
      display: none;
    }
    .category-dropdown {
      display: block;
    }
  }

  .search-tools {
    background: #f5f9fd;
    padding: 8px;
    display: flex;
    max-width: 440px;
    margin: 0 auto;
    margin-bottom: 23px;
    border-radius: 10px;
}

.search-tools .search-bar {
    width: 100%;
    border: none;
}
.search-tools .search-bar input{ 
    border: none;
}