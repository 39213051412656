.pricing-box h3 { 
    font-size: 20px!important; 
    font-weight: 700;
    color: #4701ff;
    margin-bottom: 20px;
}
.header-txt p {
    font-size: 1rem !important;
    font-weight: 400 !important;
}
.card.pricing-box {
    padding: 20px 30px 10px;
}
.pricing-box ul {
    padding: 0;
}
.pricing-box ul li{
    list-style: none;
    display: flex;
    font-size: 16px;
    padding: 5px;
}
.pricing-box i.tickMark{
    color: #2fca2f;
    font-size: 19px;
    font-weight: 600;
    margin: 0 18px 0 0;
}
.page-layout .accordion-header button {
    font-size: 15px;
    font-weight: 600;
}
.accordion-button:not(.collapsed) {
    color: #000;
    background-color: #ffffff;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%); 
}
.buttonWrap{
    margin: 30px 0 20px;
}
.pricingTabs.nav-tabs {
    border-bottom: 0.0625rem solid #dee2e6;
    border: none;
    margin: 0 auto 30px !important;
    width: auto;
    background: white;
    display: table;
    text-align: center;
    border-radius: 20px;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 25%);
}
.pricingTabs.nav-tabs .nav-link { 
    padding: 6px 20px;
}
.pricingTabs.nav-tabs .nav-item {
    margin-bottom: -0.0625rem;
    display: inline-block;
    border: none;
    border-radius: 10px;
    font-size: 15px;
}
.pricingTabs.nav-tabs .nav-link.active, .pricingTabs.nav-tabs .nav-item.show .nav-link {  
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%); 
    background: linear-gradient(90deg,#4701ff 35%,#8223f7);
    color: #fff;
    border: none;
    border-radius: 20px;
}
.loaderInline {
    height: 54px;
    text-align: center;
    margin: -18px auto 0;
}
.loaderInline .loader {
    margin: 0 auto !important;
    transform: scale(0.7);
}
 .pricing-box h5.card-subtitle {
    font-size: 40px !important;
    font-weight: 700 !important; 
}
.pricing-box h5.card-subtitle span {
    font-size: 17px;
    color: #8898aa;
    font-weight: 500;
} 
.pricing-box .btn-primary-outline {
    border: 2px solid #4701ff;
    color: black;
}
.pricing-box .btn-primary-outline:hover {
    color:#fff;
    background: linear-gradient(90deg,#4701ff 35%,#8223f7);
}
.faqs .accordion-flush .accordion-item {
    background: #f8f8fd;
    border: none !important;
}
.card-subtitle-custom{
    font-size: 40px;
    font-weight: 500 !important;
    color: #525f7f;
    margin-bottom: -6px !important;
}
.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.9;
    -webkit-transition: .2s;
    transition: opacity .2s;
    border-radius:5px;
  }
  
  .slider:hover {
    opacity: 1;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background-color: #6a3dfd;
    border-color: #6a3dfd;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    cursor: pointer;
    border-radius:50%;
  }
  
  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background-color: #6a3dfd;
    border-color: #6a3dfd;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    cursor: pointer;
    border-radius:50%;
  }
  .rangeScale {
    display: flex;
    height: 15px;
    position: absolute;
    left: 10px;
    right: 10px;
    z-index: 0;
    border: 0;
    flex-wrap: nowrap;
    justify-content: space-between;
    top: 6px;
}

.rangeWrap {
    position: relative;
    margin-top: 20px;
}

.rangeWrap input {
    z-index: 9;
    position: relative;
}

.rangeScale span {
    width: 2px;
    background: #dcdcdc;
}
.offerTxt {
    position: absolute;
    right: 50px;
    top: 50px;
    float: right;
    background: #ffd400;
    padding: 3px 10px;
    border-radius: 35px;
    font-size: 12px;
    color: #333;
    font-weight: bold;
}
.header-txt.sub-head p {
    max-width: 554px;
    text-align: center;
    margin: 0 auto;
    line-height: 25px;
    font-size: 16px !important;
}
.activebox{
    border: #6a3dfd85 solid 1px;
}

.offer-page{
    text-align: center;
    text-align: center;
    padding: 5% 10px;
}
.offer-page h2{
    font-size: 22px;
    margin-top: 20px;
    margin-bottom: 40px;
    font-weight: 600;
}


.logoClass{
    max-width: 190px;
    margin: 0px auto 80px;
}
.offer-code {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 40px;
    box-sizing: border-box;
    background-color: #e4e9f0;
    border-radius: 8px;
  }
  
  .offer-code label {
    font-size: 1rem;
    margin-bottom: 8px;
  }
  
  .offer-code input, .offer-code textarea {
    width: 100%;
    padding: 8px;
    font-size: 1rem;
    margin-bottom: 16px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    text-align: center;
  }
  
  .offer-code button {
    width: 100%;
    padding: 10px;
    font-size: 1rem; 
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
   
  .offer-code p {
    margin-top: 16px;
    font-size: 1rem;
    color: #f44336;
  }
  p.success {
    color: #009b0c;
}


.userData .row {display: flex;}

.userData {
    max-width: 500px;
    margin: 0 auto;
    text-align: left;
    margin-bottom: 60px;
    margin-top: 10px;
    background: #e4e9f0;
    padding: 20px;
    border-radius: 10px;
}

.userData .row {
    padding: 15px;
    font-size: 15px;
}

.userData ul {
    margin: 0;
    list-style: none;
    padding: 0;
}

.userData ul li {
    margin-right: 10px;
}
.userData .row:last-child {
    border: none;
}
.userData .row {
    padding: 15px;
    border-bottom: 1px solid #fff;
}
.userData .row .col:first-child {
    font-weight: bold;
}
.offer-page h2 span {
    font-weight: normal;
    font-size: 16px;
    margin-left: 30px;
}
.inline-pricing{
    padding: 0;
}
.inline-pricing .header-txt{
    padding: 0;
}
.inline-pricing .pricingTabs.nav-tabs { 
    margin: 30px auto 0px !important;
}
.inline-pricing .pricing-box h5.card-subtitle {
    font-size: 22px !important;
    font-weight: 700 !important;
    margin-bottom: 20px !important;
    height: inherit;
    display: inline-block;
}
.inline-pricing .card.pricing-box {
    padding: 0;
    text-align: left;
}
.inline-pricing .pricing-box .card-title {
    margin:0 !important;
    font-size: 18px;
}
.inline-pricing .pricing-box .unlimited-words {
    margin: 0 0 7px !important;
    font-size: 24px !important;
} 
.inline-pricing .pricing-box .pricing-box h5{
    margin:0 !important;
}
.planFeatures{
    display: flex;
    align-content: flex-start;
    align-items: center;
    justify-content: space-evenly;
    padding: 0px 0; 
    margin: 5px auto 0;
    border-radius: 10px; 
    color: #7f7f7f;
}
.inline-pricing .offerTxt {
    position: absolute;
    left: 16px;
    top: -17px;
    float: right;
    background: #ffd400;
    padding: 3px 10px;
    border-radius: 35px;
    font-size: 12px;
    color: #333;
    font-weight: bold;
    border: 5px solid #fff;
    max-width: 96px;

    display: none;
}
.planIncludes strong{
    text-align: left;
    display: block;
    margin-bottom: 10px;
    font-size: 15px;
}
.planIncludes ul {
    text-align: left;
    display: inline-block;
    list-style: none;
    padding: 0;
    font-size: 14px;
    margin: 0;
}
.planIncludes ul li {
    display: flex;
    padding: 3px 0;
}
.planIncludes ul li .tickMark{
    margin-right: 10px;
    color: #1ccd3a;
    font-weight: bold;
    font-size: 14px;
} 
.perMonth {
    font-size: 13px !important; 
    float: left;
    width: 100%;
    text-align: left;
    height: 20px;
    margin: 0 auto;
}
.inline-pricing .pricBtn{
    display: block; 
    padding: 6px 20px;
    height: auto;
    font-size: 15px !important;
    text-align: left;
    max-width: 152px; 
    margin: 0;
}
p.caption {
    font-size: 15px !important;
    line-height: 19px;
    color: #8f8f8f;
    margin-bottom: 10px;
}
.inline-pricing .text-mute{
    font-size: 12px;
}
.page-layout.inline-pricing.inPage {
    margin-top: 60px;
}
.page-layout.inline-pricing.inPage .tab-content{
    max-width: 618px;
    margin: 0 auto;
}
.inline-pricing .loaderInline {
    height: 34px;
    text-align: center;
    margin: 0 auto 0;
    padding-top: 5px;
}
.inline-pricing .pricing-box .card-body { 
    padding: 20px 30px;
}
.inline-pricing .pricing-box h5.card-subtitle { 
    display: flex;
    align-items: center;
    float: left;
    width: 100%;
    margin: 5px 0;
}
span.perMonth {
    display: inline-block;  
    padding: 2px 5px;
    color: #333 !important;
    height: 26px;
    border-radius: 5px;
    line-height: 23px;
    margin: 0 16px;
}
h5.card-subtitle.strick .amt{
    text-decoration:line-through;
    font-size: 26px;
}
span.perMonth.atYear {
    margin-left: 0px;
    padding: 2px 10px;
}
.offHead {
    margin: 0 0 14px;
    color: #333;
    font-weight: 600;
    font-size: 12px;
    background: #ffee5f;
    display: inline-block;
    padding: 2px 5px;
    border-radius: 5px;
}
.offHead.act{
    background: #a6e3ff; 
}